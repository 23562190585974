import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './AuthContext.js';
import { programmes, courseToBundleMap } from './metaprogrammes.js';

const Profile = () => {
    const { currentUser, userData, loading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser) {
            navigate('/'); // Redirige si l'utilisateur n'est pas connecté
        }
    }, [currentUser, navigate]);

    if (loading) {
        return <><p>Chargement...</p></>;
    }

    if (!userData) {
        return <><p>Aucune donnée utilisateur disponible. Veuillez vérifier votre connexion ou valider votre compte (lien de vérification par e-mail).</p></>;
    }

    const purchasedCourses = programmes.filter(programme =>
        userData.coursAchetes.includes(courseToBundleMap[programme.id])
    );

    return (
        <>
            <div className="profile-container">
                <h1>Mon Profil</h1>
                <div className="profile-info">
                    <p><strong>Prénom :</strong> {userData.prenom || 'Non renseigné'}</p>
                    <p><strong>Nom :</strong> {userData.nom || 'Non renseigné'}</p>
                    <p><strong>Email :</strong> {userData.email}</p>

                    <p>
                    <strong>Statut du compte :</strong> {userData.isSubscribed ? 'Abonné' : 'Non abonné'}
                    <br /><br />
                    </p>
                    {/* Affiche le lien pour gérer les abonnements uniquement si abonné */}
                    {userData.isSubscribed && (
                    <a
                        href={process.env.REACT_APP_GESTION_ABO_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="manage-subscription-link"
                    >
                        Gérer mes abonnements
                    </a>
                    )}
                </div>

                <div className="profile-courses">
                    <h2>Mes cours</h2>
                    {purchasedCourses.length > 0 ? (
                        <ul>
                            {purchasedCourses.map((programme) => (
                                <li key={programme.id}>
                                    <h3>{programme.title}</h3>
                                    <p>{programme.description[0]}</p>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>Aucun cours.</p>
                    )}
                    <Link to="/cours">Accéder aux cours</Link>
                </div>
            </div>
        </>
    );
};

export default Profile;
