import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext.js';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './donnees/firebase.js'; 

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkAdminAccess = async () => {
      if (!currentUser) {
        setError('Utilisateur non connecté.');
        setIsAdmin(false);
        setLoading(false);
        return;
      }

      try {
        const adminDocRef = doc(db, 'admin_users', currentUser.uid);
        const adminDoc = await getDoc(adminDocRef);

        if (adminDoc.exists()) {
          setIsAdmin(true);
          setError(null); // Aucune erreur
        } else {
          setError(`UID non trouvé dans admin_users.`);
          setIsAdmin(false);
        }
      } catch (err) {
        console.error('Erreur lors de la vérification des droits admin :', err);
        setError(`Erreur Firestore : ${err.message}`);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    checkAdminAccess();
  }, [currentUser]);

  // Redirection si l'utilisateur n'est pas connecté
  if (!currentUser) {
    return <Navigate to="/" replace />;
  }

  // Affichage pendant le chargement
  if (loading) {
    return <p>Chargement en cours...</p>;
  }

  // Affichage des erreurs (mode débogage ou dev)
  if (error) {
    return (
      <div>
        <h2>Erreur d'accès</h2>
        <p>{error}</p>
      </div>
    );
  }

  // Affichage si l'utilisateur n'est pas admin
  if (!isAdmin) {
    return (
      <div>
        <h2>Accès refusé</h2>
        <p>Vous n'avez pas les droits nécessaires pour accéder à cette page.</p>
      </div>
    );
  }

  // Contenu protégé si tout est OK
  return children;
};

export default PrivateRoute;
