import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ScrollToTopButton = () => {
    const seuil = 1200
    const [isVisible, setIsVisible] = useState(false);
    // Ajout d'un état pour suivre si le seuil de défilement a été atteint
    const [isScrollBeyondThreshold, setIsScrollBeyondThreshold] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    //const [key, setKey] = useState(0);

    const refreshPage = () => {
        //setKey(prevKey => prevKey + 1);
        navigate(location.pathname);
    };

    useEffect(() => {
        const checkScrollThreshold = () => {
        if (window.scrollY > seuil) {
            setIsScrollBeyondThreshold(true);
        } else {
            setIsScrollBeyondThreshold(false);
            setIsVisible(false);
        }
        };

        const mouseMoveHandler = (event) => {
            const isCursorNearTop = event.clientY <= 130;
            const isCursorNearLeft = event.clientX <= 100;
            const isCursorNearButton = isCursorNearTop || isCursorNearLeft;

            // Mettre à jour la visibilité basée sur le défilement et la position du curseur
            setIsVisible(isScrollBeyondThreshold && isCursorNearButton);
        };

        window.addEventListener('scroll', checkScrollThreshold);
        window.addEventListener('mousemove', mouseMoveHandler);

        return () => {
        window.removeEventListener('scroll', checkScrollThreshold);
        window.removeEventListener('mousemove', mouseMoveHandler);
        };
    }, [isScrollBeyondThreshold]); 

    return (
        isVisible && (
        <button
            onClick={refreshPage}
            style={{
            position: 'fixed',
            left: '20px',
            top: '20px',
            cursor: 'pointer',
            zIndex: 100,
            border: 'none'
            }}
        >
            <img src="../pictures/fleche.png" alt="↑ Haut" style={{ transform: 'scaleY(-1)', width: '50px' }} />
        </button> 
        )
    );
};

export default ScrollToTopButton;
