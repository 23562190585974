import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';

function Chapitre3Croissance() {
  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 3 : Entre confort et inconfort, et liberté</h1>

        <p>
        On peut parler d'équilibre entre le confort et l'inconfort car on sait que l'un peut amener à l'autre. C'est quelque chose d'assez subjectif, certaines personnes trouveront un confort dans une situation très inconfortable pour d'autres. Mais au-delà de cette subjectivité, chacun peut ressentir, par exemple, de l'inconfort à force d'être dans un excès de confort. L'inverse pourrait s'avérer aussi vrai : si une personne risque des choses, au premier abord, peut-être qu'elle se sentira inconfortable, et par la suite, par certaines circonstances, elle pourrait découvrir un nouveau confort qu'elle ne connaissait pas.
        On dit souvent qu'il faut étendre sa zone de confort, ou bien aller au-delà. Peut-être que finalement, ce n'est qu'une zone crée par nos soins, et par la même, un état qui nous conditionne.
        Peut-on être bien dans l'inconfort ?<br /><br />
        Premièrement, il est bon de savoir s'écouter, écouter son corps, écouter ses ressentis et son intuition, car risquer, ou se lancer dans quelque chose, peut aussi découler de désirs égotiques et de peurs (du jugement ou de manque par exemple). 
        Mais s'aventurer hors de sa zone de confort, c'est inviter l'inconnu et, avec lui, une chance d'apprendre et de découvrir. J'ai été inspiré par des messages qui disaient que l'erreur n'existe pas, et c'est très vrai : l'erreur est subjective, il n'y a en fait que leçon.
        </p>
        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable bold underline">
          La magie réside peut-être dans notre capacité à reconnaître que la zone de confort n'est pas réelle. C'est seulement à quel point tu valides mentalement qu'une situation est sécurisante ou pas.
          </p>
        </animated.div>
        <p>
        Derrière certaines barrières mentales, notre désir pourrait se révéler et être tout autre que ce qu'on a comme présent projet.
        <br /><br />
        </p>

        <img
          src="/pictures/gratitude.png"
          alt="cours développement personnel gratitude"
        />

        <p>
        Selon le schéma ci-dessus, on peut en extraire l'information que chaque zone est un état d'esprit présent qui nous autorise plus ou moins de choses. On pourrait bien-sûr concevoir un schéma de la zone de confort de différente manière. Mais concentrons-nous sur celui-ci.
        <br /><br />
        Plus la peur est présente et contrôlante, plus je risque d'être dans la première zone où certaines peurs d'insécurité peuvent nous rendre avec un état d'esprit “anti-magie” (non spontané, la spontanéité étant le principe qui rend la vie magique).
        <br />
        </p>
        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
          On peut s'apercevoir qu'une première phase pour s'en libérer est le contact avec la peur (et les émotions) : je me confronte à mes peurs, à mes jugements et croyances limitantes.
          </p>
        </animated.div>
        <p>
        Ensuite, plus je “fais avec” et j'avance sans subir mes ressentis, plus je me montre de nouvelles facettes de la vie et de qui je suis, plus j'apprends et je m'ouvre à mon potentiel.
        <br />
        La zone de magie c'est le cœur qui est à l'œuvre, la peur n'ayant plus d'impact sur nos choix et nos actions. <br /><br />
        Alors comment favoriser cet apprentissage pour incarner de plus en plus un état d'esprit proche de cela ?<br />
        </p>
        <p>
        Première chose : accueillir ses émotions avec bienveillance. Ce qui signifie ne pas lutter contre et faire de la place en soi pour que les peurs ne soient plus subies mais acceptées et rendues conscientes. Être empathique pour soi est un tremplin fantastique pour arrêter de se faire plus de mal qu'autre chose. Ainsi, je peux me concentrer sur une progression constante en étant souple et résilient. <br /><br />
        La confiance en soi se cultive par des actions concrètes. Chaque succès, aussi minime soit-il, est un pas vers l'intégration d'une estime saine pour soi-même. Ainsi, mieux j'accepte mes émotions pour en faire un processus évolutif, plus cela devient non pas un fardeau mais un cadeau que je me fais.
        <br /> <br />
        La gratitude est un facteur clé que j'aborde dans un chapitre suivant. <br /><br />
        Être dans cet état d'esprit libre dont je parle est profondément lié à l'écoute de son cœur. Pour le développer, il est nécessaire de : <br />
        Être un(e) rebel(le) mais pas de son intuition. Un enfant peut souvent, surtout dans notre société, être considéré comme un rebel. Pourquoi ? Parce qu'il a un esprit neuf et qu'il n'hésite pas à remettre en question les idées reçues ou les normes sociales. La plupart du temps, nous sommes plus des rebels de notre propre intuition, ce qui nous emmène dans des situations délicates qui nous embêtent.<br /><br />
        Gérer ses pics de cortisol (le stress) car la plupart du temps, lorsqu'on subit de l'angoisse ou du stress c'est qu'on ne sait plus vivre dans le moment présent.
        Je ne peux pas être stressé car je ne suis pas encore dans la situation. Ou alors, si je suis stressé c'est que je me projette dans un faux futur dans lequel j'échoue, ou je me sens amoindri, jugé, blessé etc.
        </p>

        <p><br /><br /></p>
        <div className="button">
          <Link to="/formationCroissance" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre3Croissance;
