import { useImperativeHandle, forwardRef } from "react";
import confetti from "canvas-confetti";

const Confetti = forwardRef((_, ref) => {
  // La fonction qui déclenche les confettis
  const fire = () => {
    const count = 200;
    const defaults = {
      origin: { y: 0.7 }, // Point de départ des confettis
    };

    const fireOne = (particleRatio, opts) => {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    };

    // Exécuter plusieurs tirs avec des paramètres variés
    fireOne(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fireOne(0.2, {
      spread: 60,
    });
    fireOne(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fireOne(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fireOne(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  // Expose la méthode fire via le ref
  useImperativeHandle(ref, () => ({
    fire,
  }));

  return null; // Le composant ne rend rien visuellement
});

export default Confetti;
