import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import AnimatedH2 from '../animations/animatedH2.js';

function Chapitre9Secouter() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();
  const [animationStyle5, ref5] = useAnimatedLine();
  const [animationStyle6, ref6] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 9 : La respiration, clé de l'apaisement mental</h1>

        <p>
          La respiration est bien plus qu'une simple fonction biologique. 
          C'est un pont direct entre le corps et l'esprit. Souvent sous-estimée dans son rôle, 
          elle est pourtant l'une des meilleures alliées pour réguler les pensées, calmer les émotions et ramener l'équilibre intérieur.
        </p>

        <AnimatedH2 className="subsection-title">1. <strong>Le lien direct entre respiration et mental</strong></AnimatedH2>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            Chaque pensée, chaque émotion affecte la respiration, et inversement. Par exemple, lorsqu'on est stressé, 
            notre respiration devient rapide et superficielle, alimentant ainsi l'anxiété et le flot de pensées confuses. 
            En revanche, une respiration profonde et régulière agit comme une régulation des turbulences nerveuses.
          </p>
        </animated.div>

        <p>
          Maîtriser sa respiration revient donc à mieux gérer ses pensées et son état émotionnel. 
          Essayer de s'apaiser par la respiration, c'est comme débrancher un ventilateur pour l'arrêter. 
          La source d'énergie est coupée, et c'est un premier pas. Mais si un vent fort souffle toujours, les pales continueront de tourner. 
          De même, apaiser son corps par la respiration est utile, mais il faut aussi prendre en compte le “vent” des pensées 
          entraînées par des émotions et des circonstances extérieures qui maintiennent l'agitation.
        </p>

        <AnimatedH2 className="subsection-title">2. <strong>Différentes techniques de respiration pour apaiser l'esprit et réguler les forces mentales</strong></AnimatedH2>

        <h3 className="technique-title"><strong>La respiration abdominale</strong></h3>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            C'est une des bases. Souvent, sous l'effet du stress, nous avons tendance à respirer de manière superficielle, 
            uniquement dans le haut de la poitrine. La respiration abdominale consiste à respirer profondément par le nez en démarrant 
            par gonfler le bas du ventre et en remontant. Cela permet une plus grande oxygénation du corps et un apaisement rapide du système nerveux.
          </p>
        </animated.div>

        <p>
          Tu peux utiliser ta main sur ton ventre pour mieux ressentir que tu respires au bon endroit. Expire doucement par la bouche. 
          Retenir légèrement ta respiration après avoir inspiré permet à tes poumons d'optimiser l'échange d'oxygène. 
          C'est un peu comme si tu laissais ton corps "digérer" l'oxygène, en lui donnant le temps de bien l'absorber avant d'expirer.
        </p>

        <h3 className="technique-title"><strong>La cohérence cardiaque</strong></h3>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
            C'est une technique simple mais puissante pour harmoniser le rythme cardiaque avec la respiration, et donc apaiser le mental. 
            Elle consiste à respirer à un rythme précis : 5 secondes d'inspiration et 5 secondes d'expiration pendant 5 minutes. 
            Pratiquée régulièrement, elle réduit le stress et améliore la clarté mentale.
          </p>
        </animated.div>

        <h3 className="technique-title"><strong>La technique du souffle alterné (Nadi Shodhana)</strong></h3>

        <p>
          Issue du yoga, cette technique permet de purifier les canaux énergétiques du corps tout en équilibrant les hémisphères cérébraux, 
          favorisant ainsi une meilleure concentration et une réduction du stress.
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
            <strong>Comment la pratiquer :</strong> Assis confortablement, avec le dos droit, place ton pouce droit sur ta narine droite et inspire lentement par la narine gauche. 
            Puis, ferme la narine gauche avec l'annulaire et expire par la narine droite. Continue en alternant l'inspiration et l'expiration d'une narine à l'autre.
          </p>
        </animated.div>

        <p>
          Pratique pendant 5 à 10 minutes pour des effets calmants. Commence par une expiration complète. 
          Puis, alterne les narines à chaque respiration. Le cycle complet est : <em>inspire à gauche, expire à droite, inspire à droite, expire à gauche.</em>
        </p>

        <animated.div className="animated-line" style={animationStyle5} ref={ref5}>
          <p className="animatable">
            Pour les débutants, commencez par des cycles de 30 secondes à 1 minute. Progressivement, augmentez jusqu'à 5-10 minutes. 
            Cette pratique aide à équilibrer les hémisphères cérébraux, à réduire le stress, à améliorer la concentration et à purifier les canaux énergétiques du corps.
          </p>
        </animated.div>

        <AnimatedH2 className="subsection-title">Les effets de la respiration consciente</AnimatedH2>

        <animated.div className="animated-line" style={animationStyle6} ref={ref6}>
          <p className="animatable">
            Prendre conscience de sa respiration est un moyen simple mais incroyablement puissant pour réguler notre état mental. 
            Une respiration maîtrisée peut littéralement transformer l'expérience de l'instant présent. 
            En respirant profondément et consciemment, on active le système nerveux parasympathique, responsable du repos et de la digestion, 
            ce qui contrebalance l'effet du système sympathique lié au stress.
          </p>
        </animated.div>

        <p>
          L'un des effets les plus immédiats d'une respiration consciente est qu'elle ramène l'attention dans le corps et dans le moment présent. 
          Souvent, nos pensées nous transportent soit dans le passé à travers des ruminations, soit dans le futur avec des anticipations anxieuses. 
          En respirant profondément, on interrompt cette boucle mentale et on se reconnecte à l'instant présent. Cet ancrage est essentiel pour retrouver un calme intérieur, 
          car il permet de couper court à l'agitation mentale. Plus on est ancré dans le présent, moins le mental a de prises sur nous.
        </p>

        <p>
          En la pratiquant comme une habitude quotidienne, on peut transformer son rapport au mental, retrouver une paix intérieure et réduire les moments de stress. 
          La respiration est un outil simple, gratuit et très puissant (alors qu'on fait comme si c'était has been). 
          Maîtriser son corps et son esprit, c'est tellement une clé importante, une base ouvrant la porte à de nombreuses autres capacités et soutenant de nombreux domaines de notre vie.<br /><br /><br /><br />
        </p>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre9Secouter;
