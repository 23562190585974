import { useRef, useEffect } from 'react';
import { useSpring } from '@react-spring/web';

const useAnimatedLine = () => {
  const ref = useRef(null);
  const [animationStyle, api] = useSpring(() => ({
    opacity: 0,
    transform: 'translateY(75px)',
    config: { tension: 120, friction: 14 },
  }));

  useEffect(() => {
    const targetElement = ref.current;
    if (!targetElement) return;

    let isVisible = false;

    const observer = new IntersectionObserver(
      ([entry]) => {
        isVisible = entry.isIntersecting;
      },
      { threshold: 0.1 } // Seuil pour détecter l'entrée
    );

    observer.observe(targetElement);

    const handleScroll = () => {
      if (isVisible && targetElement) {
        const rect = targetElement.getBoundingClientRect();
        const screenHeight = window.innerHeight;

        const elementMiddle = rect.top + rect.height / 2;
        const distanceFromMiddle = Math.abs(screenHeight / 2 - elementMiddle) / (screenHeight / 2);

        const maxTranslateY = 150;
        const translateY = Math.max(
          -maxTranslateY,
          Math.min(maxTranslateY, distanceFromMiddle * 150 * (elementMiddle < screenHeight / 2 ? -1 : 1))
        );

        const opacity = Math.max(0, Math.min(1, 1 - distanceFromMiddle));
        api.start({ opacity, transform: `translateY(${translateY}px)` });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, [api]);

  return [animationStyle, ref];
};

export default useAnimatedLine;
