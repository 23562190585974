import React, { useState, useRef } from 'react';
import './modalNewsletter.css'; 
import Confetti from "./animations/confettis.js"

const NewsletterModal = ({ isOpen, onClose, onSubscribe }) => {
  const [pseudo, setPseudo] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(''); 
  const [messageType, setMessageType] = useState('');
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const confettiRef = useRef(null);

  if (!isOpen) return null;

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePseudo = (pseudo) => {
    const pseudoRegex = /^[a-zA-Z\u00C0-\u017F0-9 _-]{3,20}$/; // Lettres avec accents, chiffres, etc.
    return pseudoRegex.test(pseudo);
  };

  const success = () => {
    confettiRef.current && confettiRef.current.fire(); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMessage('');
    setMessageType('');

    if (!email.trim()) {
      setMessage('Le champ email doit être rempli.');
      setMessageType('error');
      return;
    }
  
    if (!pseudo.trim()) {
      setMessage('Le champ pseudo doit être rempli.');
      setMessageType('error');
      return;
    }

    if (!validateEmail(email)) {
      setMessage('Adresse email invalide.');
      setMessageType('error');
      return;
    }

    if (!validatePseudo(pseudo)) {
      setMessage('Votre pseudo doit contenir entre 3 et 20 caractères, uniquement des lettres (accents autorisés), chiffres, espaces, tirets ou underscores.');
      setMessageType('error');
      return;
    }

    setIsLoading(true);

    try {
      const result = await onSubscribe({ email, pseudo }); // Appelle la fonction d'inscription
      if (result.success) {
        setMessage(result.message); // Affiche "Inscription réussie !"
        setMessageType('success');
        setPseudo('');
        setEmail('');

        success();
        setTimeout(() => {
          setIsClosing(true); 
          setTimeout(() => {
            onClose(); 
          }, 500); // Durée de l'animation CSS
        }, 3800); // Délai après le message
      } else {
        setMessage(result.message); // Affiche l'erreur retournée par `onSubscribe`
        setMessageType('error');
      }
    } catch (error) {
      console.error('Erreur lors de l\'inscription :', error);
      setMessage('Une erreur est survenue. Veuillez réessayer.');
      setMessageType('error');
    } finally {
      setIsLoading(false); 
    }
  };
  
  return (
    <>
      <Confetti ref={confettiRef} />
      <div className={`newsletter-overlay ${isClosing ? 'closing' : ''}`}>
        <div className="newsletter-modal">
          <button className="newsletter-close-button" onClick={() => {
              setIsClosing(true);
              setTimeout(() => {
                onClose();
              }, 500); 
            }}
          >
            &times;
          </button>
          <h1>Inscrivez-vous à la newsletter !</h1>
          <p>Recevez <b>votre Ebook</b> sur la capacité à <b>S'Auto-Valider</b> dans ses choix, ses projets et plus généralement dans ses croyances !<br />
            <em>Renseignez simplement votre superbe pseudo et email :</em>
          </p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Votre pseudo"
              value={pseudo}
              onChange={(e) => setPseudo(e.target.value)}
              className="newsletter-input"
              required
            />
            <input
              type="email"
              placeholder="Votre email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="newsletter-input"
              required
            />
            <button
              type="submit"
              className="newsletter-submit-button"
              disabled={isLoading} // Désactive le bouton pendant le chargement
            >
              {isLoading ? "Inscription en cours..." : "S'inscrire"}
            </button>

            {message && (
              <p className={`message ${messageType}`}>
                {message}
              </p>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default NewsletterModal;
