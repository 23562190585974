import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import LoadingGif from '../animations/loadingGif.js';

function Chapitre5Saimer() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();
  const [animationStyle5, ref5] = useAnimatedLine();
  const [animationStyle6, ref6] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 5 : Estime</h1>

        <p>Tout part d'un principe intérieur, et tout le monde fait comme si l'apparence (ce qui est finalement le résultat d'une myriade de facteurs) est ce qui est important, voir ce qui influe le plus largement.
        </p>
        <p>La page blanche, c'est de là que part le dessin ou le texte. Tout part du non-manifesté.<br /><br />
        C'est pourtant un principe qui est nettement ignoré. Beaucoup ne croit pas en ce qu'il ne voit pas, pourtant, voyez-vous les ondes ? Les ondes wifi, Bluetooth... ?  Et vous n'avez pas à y croire pour que ça fonctionne, n'est-ce pas ?</p>
        
        <p>En réalité, l'apparence est le résultat d'idées qu'on a suivies. Mais peut-être aussi que des peurs s'en sont mêlées... </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">En tout cas, on a à chaque fois suivi des choses qui provenaient d'abord de l'intérieur (désirs, idées, joie, ...), éventuellement déformées par des craintes, et autres pensées.</p>
        </animated.div>

        <p>
          Donc, on poursuit des actions et on marche sur des chemins indiqués par des intentions et des idées qu'on a eues. Et par la suite, on croit parfois que seul le résultat à du pouvoir et de l'importance.
        </p>
        
        <LoadingGif
          src="/pictures/moiteacher2.gif"
          placeholder="/pictures/moiteacher2.png"
          alt="cours développement personnel"
        />
        <p>
          Par exemple, sociétalement, les modes dictent certains comportements et engendrent donc des choix qui peuvent aller à l'encontre de nos propres valeurs et/ou goûts. Il y a de très bonnes “tendances” parfois, mais sous l'avidité de certains, elles se transforment et deviennent des excuses pour obtenir plus.
        </p>

        <p>
          Parfois, ce qui fonctionne financièrement n'est qu'un effort de plus dans cette avidité. 
          Ça ne devrait pas être la normalité et ça ne le sera plus dans un certain temps. Car la Nature est généreuse mais elle ne peut pas donner indéfiniment à des personnes avides.<br /><br />
          La valeur d'une personne n'est pas sa richesse financière.<br /><br />

          Ainsi, <span className="gold">ce qui compte le plus n'est pas l'attachement à ce qui paraît, mais l'ouverture à ce qui pourrait être :</span> si on a un défaut qu'on aime vraiment pas, au lieu de s'attacher à ce qui paraît maintenant, il vaut mieux s'ouvrir à ce qu'on souhaite développer chez soi.
          Si on prête trop d'attention au bouton sur notre visage, on renforce ce petit “défaut”, au lieu de voir la beauté en nous.
        </p>
        
        <p>
          Une citation au féminin d'Audrey Hepburn est très juste ici :
        </p>
        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
        <p className="animatable">
          “Pour avoir des lèvres attirantes, prononcez des paroles de  bonté. Pour avoir de beaux yeux, regardez ce que les gens ont de beau en  eux. Pour rester mince, partagez vos repas avec ceux qui ont faim. Pour avoir de beaux cheveux, faites qu'un enfant y passe sa main chaque  jour. Pour avoir un beau maintien, marchez en sachant que vous n'êtes  jamais seule.”
        </p>
        </animated.div>
       
        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
        <p className="animatable">
        Ce qu'elle exprime est le fait que notamment la vraie beauté, vient non pas de la souffrance, du sur-contrôle du corps, ou de produits cosmétiques, mais d'un état d'esprit et d'une beauté qu'on pourrait qualifié “intérieure” mais qui n'est en rien séparé de la beauté totale de la personne.
        </p>
        </animated.div>

        <p>
          Notre situation, nos défis, et nos soit-disant défauts ne doivent pas nous faire souffrir. Ils doivent justement nous faire rêver, et nous donner envie d'avancer.<br /><br />
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable gold bold underline">“Il peut sembler qu'une situation crée la souffrance, mais en fin de compte, ça n'est pas le cas - c'est la résistance qui la crée.” 
          <br /><br /></p>
          <p>- Eckhart Tolle</p>
        </animated.div>

        <p>
          L'auto-jugement est le seul et unique problème dans le manque d'estime personnel. Chaque personne qui nous renvoie un jugement qui nous touche, est un jugement qu'on s'attribue inconsciemment.<br /> 
          Si on pouvait voir ce qui est souvent le cas, c'est-à-dire que les gens ne s'aiment pas forcément eux-même, on trouverait absurde de vouloir plaire à tout le monde. <br /><br />
          Si on avait en face de nous, à chaque fois, des gens qui s'aimaient, on verrait bien que chercher à se faire aimer est déjà un manque d'amour envers soi-même. Les personnes nous diraient : “tu as peur de quoi ?”; “pourquoi tu cherches à être quelqu'un que tu n'es pas ?” etc. <br />Pas dans le sens que tu n'es pas assez, mais justement que tu es très bien comme tu es.
        </p>

        <animated.div className="animated-line" style={animationStyle5} ref={ref5}>
          <LoadingGif
            src="/pictures/moiteacher3.gif"
            placeholder="/pictures/moiteacher3.png"
            alt="cours développement personnel"
            className="animatable"
          />
        </animated.div>
        <p>
          Le ou la seul.e qui te juge c'est toi-même. Si les autres te jugent, c'est ta <b>perception</b> de leur jugement qui importe : ton consentement et ta propre vision sur toi-même.<br /><br />
          Comme solution, on ne dit pas de ne pas prendre en compte ce que les autres pensent. Mais il faut reconnaître que tout le monde projette ses ressentis, avec, potentiellement, ses mauvais filtres. Des choses qui peuvent avoir du sens ou non pour soi. Il est donc vraiment nécessaire d'adopter une conscience de soi (nos pensées, et notre ressenti) pour ne pas accepter tout ce que les autres nous  renvoient sur nous-même. Car, de plus, c'est leur propres auto-jugements et leur propres croyances. <br />
        </p>

        <animated.div className="animated-line" style={animationStyle6} ref={ref6}>
        <p className="animatable">
          Pour arrêter de t'en faire du jugement des autres, il est nécessaire d'arrêter de juger soi-même les autres. 
          Pour en revenir à la citation d'Audrey Hepburn, effectivement, je conscientise de plus en plus le fait que : plus t'es focalisé sur toi-même, plus tu peux être amené à t'observer et te juger. À l'inverse, plus tu te focalise positivement sur les autres, plus tu peux observer de belles choses sur les autres et toi-même : tu finiras pas moins te juger !
        </p>
        </animated.div>
        
        <p>
         On pense souvent que la confiance en soi est un trait de personnalité naturel, qu'on possède ou qu'on ne possède pas. 
         On se dit qu'il y a des personnes charismatiques, qui prennent toute l'attention et qui sont magnétiques - et puis on croit que d'autres personnes ne sont pas comme ça. 
         On croit qu'il y a des personnes solaires et d'autres qui sont naturellement éteintes. 
         Mais un enfant n'est jamais éteint, un enfant est naturellement solaire et plein de vie. 
         C'est en grandissant et en devenant adulte que l'on apprend à s'éteindre petit à petit, à ne pas prendre trop de place, et à être dans une forme de sur-adaptation. 
         Sans le réaliser, on s'encombre de couches qui nous empêchent d'éveiller notre lumière, qui est déjà là, en nous. <br /><br /><br />
        </p>

        <h3>L'espace en soi, l'acceptation et l'auto-validation</h3>
        <p>
          Une des clés fondamentale de l'estime de soi est l'acceptation de soi ainsi que l'empathie.
          Quoi de mieux que de se donner l'espace intérieur nécessaire pour accueillir toutes les parts de soi.
          Si vous êtes jugé un jour d'arrogant ou bien de pessimiste par exemple, de un : le seul moment où c'est important de faire cette introspection c'est si on sent en soi une réaction. Ca nous met en met mal à l'aise ou en colère... Et donc, finalement pourquoi ne pas l'être (cette part jugée) ? Parfois, la seule chose que cela indique est le fait que la personne en face voit cette même part en elle-même et ELLE ne l'accepte pas, elle se juge là-dessus. Sinon, si être face à cette part de nous, nous fait prendre conscience qu'on ne l'aime pas, on peut aussi remercier pour cette prise de conscience. Rien n'est immuable, si nous n'avons plus besoin d'un trait de personnalité ou d'un comportement, il s'effacera à mesure qu'on le conscientise et qu'on ressent le côté émotionnel à propos. Avec douceur, croyant en notre propre transformation. On est humain et aucun trait de personnalité, défaut ou qualité (encore une fois interchangeable) ne doit nous définir.
        </p>
        <p>
          Un autre part déterminante par rapport à l'estime de soi est tout ce qui est lié à notre évolution personnelle et comment on digère les choses. Par rapport à ça, je vous propose de voir le cours sur <span className='bold'>la croissance personnel.</span><br />
        </p>

        <p>
        <em><br />
          Si tu ne l'as toujours pas fait, tu peux rejoindre le groupe lié aux programmes pour parler du cours en tant que tel, ou d'autres sujets. Ça sera à cet endroit que tu pourras me poser des questions et partager avec les autres membres aussi.<br />
          Il te faut premièrement l'application "discord". Puis, tu peux rejoindre le groupe par le lien ci-dessous :
        </em>
        </p>

        <div className="button">
          <a href="https://discord.gg/7YMfW9nRzr" target="_blank" className="discord-button" rel="noopener noreferrer">Accès au groupe lié au programme</a>
        </div>

        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>

      </>
  );
}

export default Chapitre5Saimer;
