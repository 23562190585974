import React, { useEffect, useRef } from 'react';

const YouTubeVideo = ({ videoId }) => {
  const containerRef = useRef(null);
  const playerRef = useRef(null); // Référence pour suivre le joueur

  useEffect(() => {
    let isMounted = true;

    const initializePlayer = () => {
      if (!containerRef.current || !isMounted) return;

      // Crée et initialise le lecteur YouTube
      playerRef.current = new window.YT.Player(containerRef.current, {
        height: '100%',
        width: '100%',
        videoId: videoId,
        playerVars: {
          autoplay: 0,
          modestbranding: 1,
          rel: 0,
          controls: 1,
          loop: 1,
          fs: 0,
          cc_load_policy: 0,
          iv_load_policy: 3,
          autohide: 1,
        }
      });
    };

    // Charge l'API YouTube si elle n'est pas encore présente
    if (!window.YT && !window.ytScriptLoading) {
      window.ytScriptLoading = true;

      const scriptTag = document.createElement('script');
      scriptTag.src = 'https://www.youtube.com/iframe_api';
      scriptTag.async = true;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

      // Ajoute un gestionnaire pour initialiser l'API une fois chargée
      window.onYouTubeIframeAPIReady = () => {
        if (isMounted) initializePlayer();
      };
    } else if (window.YT) {
      // Si l'API est déjà chargée, initialise immédiatement
      initializePlayer();
    }

    return () => {
      // Nettoie le joueur YouTube
      isMounted = false;

      if (playerRef.current && typeof playerRef.current.destroy === 'function') {
        playerRef.current.destroy();
      }

      // Supprime la fonction globale si nécessaire
      if (window.onYouTubeIframeAPIReady) {
        delete window.onYouTubeIframeAPIReady;
      }
    };
  }, [videoId]);

  return (
      <div
        className="video-container"
        ref={containerRef}
      >
        {/* Lecteur YouTube inséré ici */}
      </div>
  );  
};

export default YouTubeVideo;
