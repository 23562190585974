import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import LoadingGif from '../animations/loadingGif.js';

function Chapitre2Croissance() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 2 : Équilibre gratitude et désir (ambition) </h1>

        <p className='gold'> 
          À quel point il faut être en gratitude et à quel point il faut en avoir marre de là où on en est, ou comment équilibrer ces deux attitudes pour avancer dans sa vie ?
        </p>

        <p>
        <b>La gratitude et le désir d'avancement</b> forment un équilibre délicat. La gratitude nous encourage à apprécier ce que nous avons déjà, à trouver de la joie dans les petites choses de la vie et à renforcer notre bien-être mental. C'est comme une barrière au pessimisme ou à toutes pensées issues de certaines peurs (peur de manque entre autres). <br /><br />
        Une des techniques pour justement repousser l'anxiété et la peur est de ressentir la gratitude (par exemple lors d'une situation qui pourrait être perçu comme anxiogène ou encore lors d'une prise de parole en publique). C'est assez logique : essayez de ressentir de la peur et de la gratitude en même temps, c'est d'expérience impossible, étant deux états d'être quasiment opposés.<br /><br />
        Il est naturel et sain d'avoir des aspirations, d'être insatisfait de certaines situations, et de vouloir s'améliorer. Cette insatisfaction peut être un puissant moteur pour le changement, nous poussant à sortir de notre zone de confort et à poursuivre nos objectifs.<br />
        On peut donc se reposer la question : à quel point la gratitude est bonne et à quel point devons-nous nous pousser à l'insatisfaction ?<br /><br />
        L'équilibre réside dans la capacité à être fier des progrès qu'on a pu réaliser, d'apprécier l'avancement présent, tout en reconnaissant qu'il y a toujours de la place pour grandir. Cultiver la gratitude aide à rester positif, tandis que le désir de progrès pousse à l'action et à l'amélioration.
        Cela dépend de chaque personne, mais trouver le juste milieu entre la gratitude et l'ambition, cet équilibre, cela permet de vivre le plus sereinement possible, sans ni regret - <u>car on accepte d'avancer avec notre ambition</u> - et sans ni frustration - <u>car on est dans un contentement de ce qui est traversé déjà</u>.
        </p>
        <p>
        Ne pas être en gratitude peut nous amener à ne pas apprécier ce qui est présent et donc à ne pas pouvoir faire avec ce qu'on a déjà. Ce qui pourrait nous garder dans un cercle vicieux, à ne <b>pas percevoir les ressources et les opportunités présentes</b> nous empêchant d'exploiter pleinement notre potentiel. <br /><br />
        À l'inverse, nous pouvons répéter des schémas en nous contentant trop d'une façon de vivre et d'agir sans aller chercher au-delà de ce qu'on a l'habitude, casser les codes, faire différemment. Trop se contenter de notre situation peut nous conduire à la stagnation. Si nous sommes trop à l'aise avec la routine et n'aspirons pas à sortir de notre zone de confort, on risque de se priver de découvertes enrichissantes et de développement personnel.<br /><br />
        L'idéal est bien sûr de trouver un équilibre entre les deux. La <b>gratitude</b> nous permet d'apprécier pleinement ce que nous avons, tandis que l'<b>ambition</b>, alliée à la volonté de s'améliorer, nous pousse à chercher des façons de sortir de notre zone de confort et d'explorer de nouvelles perspectives.
        </p>

        <LoadingGif
          src="/pictures/moiteacher2.gif"
          placeholder="/pictures/moiteacher3.png"
          alt="cours développement personnel"
        />

        <p>
        Entre des peurs de risquer, de sortir des sentiers battus, ce qui peut faire frein à notre avancement et nous empêche d'apprécier d'autant plus ce qu'on vit, ça peut se révéler être un sacré défi ! <br />Cela pourrait également nous conduire à adopter des activités qui sont moins passionnantes, non alignées à qui nous sommes, ou un mode de vie qui nous entraîne petit à petit à la déprime. 

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
          Sans assez de courage pour <b>explorer de nouvelles voies</b>, ni <b>d'appréciation du moment présent</b> nous sommes poussés dans nos retranchements à un moment ou à un autre.
          </p>
        </animated.div>

        C'est un défi constant de trouver cet <span className='gold bold'>équilibre entre la gratitude et l'aspiration à quelque chose de plus grand</span>. C'est pour cela que je te propose quelques points sur lesquelles tu peux jouer pour garder le cap et aimer tout le processus sans pour autant t'empêcher quelque chose que tu pourrais regretter; faire les choses avec cœur et avoir une bonne vision.<br /><br />

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
          Prends un moment chaque jour pour réfléchir à trois choses au moins pour lesquelles tu es reconnaissant. Cela peut être aussi simple que d'apprécier une tasse de ta boisson préférée, d'avoir une conversation agréable, ou de respirer à plein poumon un bon air frais. Tenir un journal de gratitude peut aider à ancrer cette pratique dans une routine mais ça n'est pas obligé. Un post-it sur le bureau et hop, c'est parfait !
          </p>
        </animated.div>
        
        En fait, ce qui est bien pour prendre de meilleures habitudes c'est de commencer par une action bien définie qui fait sens et qui n'est pas effectuée dans une sorte d'automatisme mental. Plus, c'est rapide et sans profondeur (sans ressenti, pas assez ancré physiquement) moins ça sera adopté dans tes habitudes de vie.<br/><br/> Tu peux jouer aussi sur plusieurs façons d'ancrer une nouvelle information : si tu souhaites commencer à manger des repas à taux de glycémie moins élevé, tu vas peut-être avoir fait des recherches sur le sujet. Tu peux l'écrire quelque part : "différentes façons de manger moins sucré". Ensuite, tu peux suivre l'élan de ton envie de changement et faire une recette maison pour un repas.
        <br />
        
        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
          Se fixer de petits objectifs qui te poussent à sortir progressivement de ta zone de confort est un bon moyen d'inspirer du renouveau et de relancer l'estime de soi. 
          </p>
        </animated.div>

        Ils peuvent être petits ou moyens, le plus important est de les faire coûte que coûte (tout du moins en se basant sur l'intention première que tu as eu). Lance-toi dans des actions qui te font ressentir <span className='gold'>la fluidité</span>. C'est cette fluidité et ton écoute intérieure qui te permettront de savoir comment changer de direction et avancer convenablement. <br />Parfois, il m'arrive de commencer quelque chose uniquement pour apprendre une leçon et je ne le sais en général qu'à la fin. Ça pourrait être une grande entreprise comme une plus petit activité, le plus important est que ça serve au final. Imagine, tu restes bloqué dans une idée de concrétiser un projet qui ne t'emmène pas là où tu le souhaites : si tu t'écoutes, tu t'arrêteras au bon moment. Sinon tu vas être logiquement en colère de ne pas renoncer. Et à l'inverse, on peut s'arrêter de découragement alors qu'on était à deux doigts de réussir quelque chose de magnifique.<br /><br />
        Pour avancer sur son chemin, quoi de mieux que de chercher l'inspiration pour s'ouvrir à de nouvelles possibilités. Lis des livres, écoute des podcasts ou suis des personnes qui ont réussi dans les domaines qui t'intéresse. Tu peux voir comment, eux, ont trouvé le courage de sortir de leur routine et ainsi être inspiré par leur parcours.<br /><br />

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable gold bold">
          Reconnaître ce pour quoi nous sommes reconnaissants et nourrir la motivation ainsi que l'ambition de transformer positivement notre vie est crucial.
          </p>
        </animated.div>

        Lorsque nous ressentons le besoin de changement et avons le courage de quitter notre zone de confort, c'est pas forcément facile, mais ça nous indique tout de même le début d'une quête vers des objectifs élevés et la réalisation de nos rêves. Quand on en a marre, ça nous donne souvent l'élan pour agir et découvrir de nouveaux horizons, tout en appréciant ce qu'on a déjà accompli et en utilisant nos expériences passées pour nous renforcer, pas pour nous  peser.<br /><br /> La gratitude nous motive à avancer, et ce désir de changement, quand il est bien canalisé, peut vraiment transformer les choses. L'essentiel étant de rester aligné à <b>nos valeurs et nos objectifs</b>.<br /><br />
        Si nous vivons notre vie avec le passé comme un fardeau, comment peut-on poursuivre sans continuer à regretter des choses ? Et surtout, ainsi, comment peut-on créer le meilleur pour la suite ? <span className='gold'>Parfois, il faut accepter que pour vivre heureux, il est nécessaire d'accepter de mériter et de pouvoir vivre ce meilleur.</span>
        </p>

        <p><br /><br /></p>
        <div className="button">
          <Link to="/formationCroissance" className="sommaire-button">Retour au sommaire</Link>
        </div>
        
      </>
  );
}

export default Chapitre2Croissance;
