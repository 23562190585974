import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useLocation, Link } from 'react-router-dom';
import Parallax from './animations/parallax.js';
import ScrollToTopButton from './animations/scrollToTopButton.js';
import { pathAliasMap } from './pathAlias.js';

import { useUI } from './UIContext.js';
import { useAuth } from './AuthContext.js';
import clsx from 'clsx';
import { throttle } from 'lodash';

const springConfig = {
  tension: 250,
  friction: 20,
  precision: 0.01,
};

// Hook pour l'animation de hover
const useHoverAnimation = () => {
  const [styles, api] = useSpring(() => ({
    scale: 1,
    config: springConfig,
  }));

  const handleMouseEnter = () => api.start({ scale: 1.06 });
  const handleMouseLeave = () => api.start({ scale: 1 });

  return { styles, handleMouseEnter, handleMouseLeave };
};

const NavLink = ({ children, to }) => {
  const { styles, handleMouseEnter, handleMouseLeave } = useHoverAnimation();

  return (
    <animated.div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={styles}
    >
      <Link to={to} className="nav-link" style={{ textDecoration: 'none' }}>
        {children}
      </Link>
    </animated.div>
  );
};

const NavButton = ({ children, onClick }) => {
  const { styles, handleMouseEnter, handleMouseLeave } = useHoverAnimation();

  return (
    <animated.div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={styles}
      onClick={onClick}
    >
      <span className="nav-link">{children}</span>
    </animated.div>
  );
};

const isBrowser = typeof window !== 'undefined';

const Navbar = ({ showParallax, isMobile }) => {
  const { openLoginModal } = useUI(); 
  const { currentUser, logout } = useAuth(); 

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const baseSommairePath = pathSegments[1];
  const isCoursePage = pathSegments.length > 2; // Vérifie s'il s'agit d'une page de cours spécifique

  const routesWithoutScrollToTopButton = ['/', '/cours', '/profile'];
  const withButton = !routesWithoutScrollToTopButton.includes(location.pathname);

  const routesWithoutCurrentPath = ['/', '/verification', '/validation_email']; 
  const showCurrentPath = !routesWithoutCurrentPath.includes(location.pathname);

  const currentPath = pathSegments
  .map(segment => pathAliasMap[segment] || segment) // Remplace les segments
  .join(" > "); // Construit la chaîne avec le séparateur " > "

  const [scrollY, setScrollY] = useState(isBrowser ? window.scrollY : 0);
  const [windowWidth, setWindowWidth] = useState(isBrowser ? window.innerWidth : 0);
  const [windowHeight, setWindowHeight] = useState(isBrowser ? window.innerHeight : 0);

  // Gestionnaire unique pour `resize`
  const handleResize = useCallback(() => {
    throttle(() => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }, 100)();
  }, []);

  // Gestionnaire pour `scroll`
  const handleScroll = useCallback(() => {
    throttle(() => {
      setScrollY(window.scrollY);
    }, 100)();
  }, []);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (isBrowser) {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleResize, handleScroll]);

  const threshold = useMemo(
    () => (windowWidth <= 680 ? windowHeight * 0.3 : windowHeight * 0.5),
    [windowWidth, windowHeight]
  );

  const navbarStyle = useSpring({
    transform: `translateY(${scrollY > threshold ? '-100%' : '0%'})`,
    opacity: scrollY > threshold ? 0 : 1,
    config: { duration: 200 },
  });

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Erreur lors de la déconnexion', error);
    }
  };

  return (
    <>
      {showParallax && <Parallax />}
      
      <animated.header style={navbarStyle} className={showParallax ? "header" : "headerColor"}>
        <nav className="navbar">
          <NavLink to="/" aria-label="Accueil">Let's start</NavLink>
          <NavLink to="/cours" aria-label="Liste des cours">Les programmes</NavLink>
          {currentUser ? (
            <>
              <NavLink to="/profile" aria-label="Profil utilisateur">Mon Profil</NavLink>
              <NavButton onClick={handleLogout} aria-label="Déconnexion">Se déconnecter</NavButton>
            </>
          ) : (
            <NavButton onClick={openLoginModal} aria-label="Connexion">Connexion</NavButton>
          )}
        </nav>
      </animated.header>

      {isCoursePage && (
        <Link to={`/${baseSommairePath}`} className="sommaire-nav">
          Retour au sommaire
        </Link>
      )}

      {showCurrentPath && (
        <div
          className={clsx('current-path-indicator', {
            'mobile-top': isMobile && showParallax,
            'course-top': !isMobile && isCoursePage,
            'default-top': !isMobile && !isCoursePage,
          })}
        >
          <p>{currentPath}</p>
        </div>
      )}
  
      {withButton && <ScrollToTopButton />}
    </>
  );
};

export default Navbar;