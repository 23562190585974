import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from './animations/animatedLine.js';
import { useAuth } from './AuthContext.js';

const FormationSaimer = () => {
  const navigate = useNavigate();
  const activeRef = useRef(null);

  const { userData } = useAuth();
  
  const [animationStyle1, ref1] = useAnimatedLine();

  const handleClick = (path, event) => {
    if (activeRef.current) {
      activeRef.current.classList.remove('active'); // Retirer la classe 'active' de l'ancien élément
    }

    const li = event.currentTarget;
    li.classList.add('active'); // Ajouter la classe 'active' à l'élément cliqué
    activeRef.current = li; // Mettre à jour la référence

    setTimeout(() => {
      navigate(path);
    }, 620); // Naviguer après la transition
  };

  useEffect(() => {
    const elements = document.querySelectorAll(".p_transi");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  return (
      <>
        <p className='p_transi'>Bienvenue <span className='gold'>{userData?.prenom ? userData.prenom : ''}</span> dans le programme</p>
        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <h1 className="animatable">S'aimer soi-même !</h1>
          <br />
          <img className="img_fleche" src="./pictures/fleche.png" alt="suivant cours développement personnel" />         
        </animated.div>

        <p style={{ textAlign: 'center' }}>
          <br/>
          Pensez-y, lire une fois lentement et une fois normalement peut permettre de mieux intégrer ce que vous lisez.
        </p>
        
        <h1>Plan du cours :</h1>
        <ul>
          <li onClick={(e) => handleClick('/formationSaimer/introduction', e)}>Introduction</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre1', e)}>Chapitre 1 : À l'intérieur</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre2', e)}>Chapitre 2 : Notre création</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre3', e)}>Chapitre 3 : Notre identité</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre4', e)}>Chapitre 4 : Émotion et empathie</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre5', e)}>Chapitre 5 : Estime</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre6', e)}>Chapitre 6 : Le sens du bonheur</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre7', e)}>Chapitre 7: L'ennui et le défaussement de soi</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre8', e)}>Chapitre 8 : La dépression</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre9', e)}>Chapitre 9 : Notre direction</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre10', e)}>Chapitre 10 : Notre regard et le miroir</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre11', e)}>Chapitre 11 : Se respecter</li>
          <li onClick={(e) => handleClick('/formationSaimer/chapitre12', e)}>Chapitre 12 : Brille !</li>
        </ul>

        <div style={{ marginTop: '10vh' }} />
        <div className="button">
          <a href="https://discord.gg/7YMfW9nRzr" target="_blank" className="discord-button" rel="noopener noreferrer">Accès au groupe lié au programme</a>
        </div>
        <p style={{ textAlign: 'center', fontSize: '1.4rem' }}>Le lien ci-dessus est une invitation au groupe. Il est nécessaire d'avoir l'application discord.</p>
      </>
  );
}

export default FormationSaimer;
