import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import LoadingGif from '../animations/loadingGif.js';

function Chapitre5Croissance() {

  const [animationStyle1, ref1] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 5 : Être présent avec l'espace qu'on crée en soi-même</h1>

        <p> 
        La paix et la sagesse, qui sont des clés du bonheur les plus essentielles, se développent à travers un facteur particulier : <em>l'espace que l'on sait créer en soi.</em>
        </p>
        <p>    
        Cet espace est ce qui permet un accueil de qui nous sommes. À l'intérieur de nous, nous avons comme plein de facettes qui peuvent s'exprimer et, parfois, qui se cachent ou qui sont réprimées pendant des années. <br />
        </p>
        <p>
        Créer de l'espace en soi : l'inverse serait de se laisser aller au bruit mental et à ses directives, ce qui donnerait de la force à nos conditionnements, nos préjugés, et nos peurs liés à des évènements - durs et peut-être traumatisants - qu'on a pu vivre dès l'enfance. <br />
        En somme, ne pas créer cet espace en soi, <b>c'est ne pas se permettre de se rendre conscient de tout notre monde intérieur.</b>
        </p>
        <LoadingGif
          src="/pictures/moiteacher1.gif"
          placeholder="/pictures/moiteacher1.png"
          alt="cours développement personnel"
        />
        <p>
        Se permettre d'autoriser toutes les parts de soi d'exister est bénéfique. Pourquoi ? Parce que tu donnes l'espace à tes parts blessées de <b>s'exprimer</b> et tu te donnes le droit de créer de l'espace aux peurs plutôt que les <em>réprimer aussi.</em> <br />
        </p>
        
        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable bold">
          À chaque fois, c'est toujours une opportunité de guérison car c'est une opportunité <span className='gold bold'>d'expression, de compréhension et de “dis-solution”</span>.
          </p>
        </animated.div>

        <p>
        À travers cette démarche, le pouvoir qu'on donnerait normalement à ces parts est amoindri voir plus du tout donné. Car on se permet de les voir, de les ressentir (=moins de réaction).
        On ne soigne pas ses blessures (soi nier) : on en apprend à travers l'expérimentation et la prise de conscience et enfin la guérison émotionnelle. <br /><br />
        Faire de l'espace pour quelque chose (peur, émotion, part de soi) c'est se donner l'opportunité de conscientiser cette chose.
        </p>
        
        <div className="button">
          <Link to="/formationCroissance" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre5Croissance;
