import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Accessibilite = () => {

  const adjustTextSize = (adjustment) => {
    const html = document.documentElement;
    const currentFontSize = window.getComputedStyle(html).fontSize;
    const currentSize = parseFloat(currentFontSize); // Extrait la valeur numérique
  
    if (adjustment === 'increase') {
      html.style.fontSize = `${currentSize * 1.1}px`; // Augmente de 10%
    } else if (adjustment === 'decrease') {
      html.style.fontSize = `${currentSize * 0.9}px`; // Réduit de 10%
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [bgColor, setBgColor] = useState('#6b44aa');

  const routesWithoutAccessibilite = ['/', '/cours', '/mentions-legales', '/conditions-generales-de-vente', '/conditions-generales-d-utilisation'];
  const withAccessibilite = !routesWithoutAccessibilite.includes(location.pathname);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    const homeElement = document.querySelector('.home');
    if (homeElement) {
      document.body.classList.toggle('dark-mode', !isDarkMode);
      homeElement.style.backgroundColor = bgColor; // Applique la couleur de fond actuelle
    }
  };

  const changeBgColor = (color) => {
    setBgColor(color);
    const homeElement = document.querySelector('.home');
    if (homeElement) {
      homeElement.style.backgroundColor = color; // Change la couleur de fond de l'élément .home
    }
  };

  useEffect(() => {
    const homeElement = document.querySelector('.home');
    if (homeElement) {
      homeElement.style.backgroundColor = bgColor; // Applique la couleur de fond
    }
  
    // Détection des appareils mobiles
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;
  
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollPosition / pageHeight) * 100;
  
      if (scrollPercent > 7 && scrollPercent < 70) { // Visible entre 7% et 80% du défilement
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    // Ajouter l'écouteur de défilement uniquement si l'appareil n'est pas mobile
    if (!isMobile) {
      window.addEventListener("scroll", handleScroll);
    }
  
    let touchTimeout;

    // Détection de l'interaction tactile sur mobile
    const handleTouchStart = () => {
      const scrollPosition = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollPosition / pageHeight) * 100;

      if (scrollPercent >= 0 && scrollPercent <= 30) { // Visible seulement entre 0% et 50% du défilement
        setIsVisible(true);

        // Annule tout temporisateur précédent pour éviter les conflits
        clearTimeout(touchTimeout);

        // Définit un nouveau temporisateur pour masquer après 3 secondes
        touchTimeout = setTimeout(() => setIsVisible(false), 3000);
      }
    };
  
    window.addEventListener("touchstart", handleTouchStart);
  
    // Nettoyage des événements lors du démontage
    return () => {
      if (!isMobile) {
        window.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("touchstart", handleTouchStart);
    };
  }, [bgColor]);  

  if (!withAccessibilite) return null;

  return (
    <div className={`accessibilite ${isVisible ? 'fadeIn' : 'fadeOut'}`}>
      <div className="accessibility-button-container">
        <button 
          onClick={toggleDarkMode} 
          className={`accessibility-button ${!isDarkMode ? 'dark-mode-button' : 'light-mode-button'}`} 
          aria-label="Basculer le mode sombre"
        ></button>

        <div className="color-options">
          <div onClick={() => changeBgColor('#6b44aa')} className="color-square" style={{ backgroundColor: '#6b44aa' }}></div>
          <div onClick={() => changeBgColor('#12396d')} className="color-square" style={{ backgroundColor: '#12396d' }}></div>
        </div>
      </div>
      <div className="text-size-buttons">
        <button onClick={() => adjustTextSize('increase')} className="accessibility-button text-larger-button" aria-label="Agrandir la taille du texte"></button>
        <button onClick={() => adjustTextSize('decrease')} className="accessibility-button text-smaller-button" aria-label="Réduire la taille du texte"></button>
      </div>
    </div>
  );
};

export default Accessibilite;
