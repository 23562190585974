import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from './donnees/firebase.js'; 
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const loadUserData = async (userId) => {
    try {
      const userDocRef = doc(db, 'utilisateurs', userId);
      const userDocSnap = await getDoc(userDocRef);
    
      if (userDocSnap.exists()) {
        setUserData(userDocSnap.data());
      } else {
        throw new Error(`Aucun document utilisateur trouvé pour UID: ${userId}`);
      }
    } catch (error) {
      throw error;
    }
  };  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
        loadUserData(user.uid);
      } else {
        setCurrentUser(null);
        setUserData(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);
  
  const updateUserData = (newUserData) => {
    setUserData(newUserData);
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUserData(null);
      setCurrentUser(null);
    } catch (error) {
      console.error("Erreur lors de la déconnexion :", error);
    }
  };

  const updateTitle = (title) => {
      document.title = title;
  };

  function updateMetaDescription(description) {
    document.querySelector('meta[name="description"]').setAttribute("content", description);
  }

  function updateSocialMeta(title, description, imageUrl, url) {
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const ogDescription = document.querySelector('meta[property="og:description"]');
    const ogImage = document.querySelector('meta[property="og:image"]');
    const ogUrl = document.querySelector('meta[property="og:url"]');
    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    const twitterDescription = document.querySelector('meta[name="twitter:description"]');
    const twitterImage = document.querySelector('meta[name="twitter:image"]');
  
    if (ogTitle) ogTitle.setAttribute("content", title);
    if (ogDescription) ogDescription.setAttribute("content", description);
    if (ogImage) ogImage.setAttribute("content", imageUrl);
    if (ogUrl) ogUrl.setAttribute("content", url || window.location.href);
  
    if (twitterTitle) twitterTitle.setAttribute("content", title);
    if (twitterDescription) twitterDescription.setAttribute("content", description);
    if (twitterImage) twitterImage.setAttribute("content", imageUrl);
  }  

  const value = {
    currentUser,
    loading,
    userData,
    
    updateUserData,
    loadUserData,
    //updateUserCoursAchetes,
    logout,
    updateTitle,
    updateMetaDescription,
    updateSocialMeta
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

