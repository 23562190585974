import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import LoadingGif from '../animations/loadingGif.js';

function Chapitre1Croissance() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 1 : Positivité authentique </h1>

        <p>
        La <span className='bold'>Positivité authentique</span>, c'est ce qui te permet de ne pas te voiler la face <span className='underline'>alors que tu souhaites rester positif</span>. Nous n'avons pas à nier nos problèmes, ni ne devons ignorer des ressentis négatifs qui doivent être <span className='bold'>reconnus et dissous</span>.
        On a besoin d'une objectivité privilégiant de reconnaître les faits, la réalité, pour grandir en conscience et <span className='gold'>faire de la réalité un tremplin plutôt qu'une façade</span>.<br /><br />
        Ce qui peut être malsain c'est d'essayer de fuir ce qui est en prônant la positivité. Combien de personne sont devenus inconsciemment négatif à force de refuser des faits ? <br /><br />
        </p>
        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
          On devrait adopter une conscience de ce qui nous fait réagir ou nous affecte intérieurement.
          </p>
        </animated.div>
        <p>
        Par exemple : j'ai un problème à un certain niveau pour ma santé. Au lieu de se vouloir juste positif et de délaisser ce souci, je vais aller voir un professionnel pour me renseigner et savoir si j'ai besoin de solutions à ce propos. On peut voir que si je fais ça pour moi, je le fais car je souhaite prendre soin de moi-même, donc c'est plus un état d'esprit où je suis “positif” car je vais dans le sens de ma guérison (c'est mon intention). Dans le cas du laisser-aller, je suis peut-être plus dans un état d'esprit faussement positif, alors que je refoule de l'anxiété. Bien sûr ça dépend des cas, c'est un exemple.<br /><br />
        </p>
        <LoadingGif
          src="/pictures/moiteacher1.gif"
          placeholder="/pictures/moiteacher1.png"
          alt="cours développement personnel"
        />
        <p>
        Si être optimiste dans la vie permet comme on le dit bien de voir le verre à moitié plein, et de se donner ainsi l'ouverture au mieux (opportunité, pensées et sentiments positifs...), nous avons en tant qu'humain très probablement certaines pensées moins joyeuses. Et le fait de ne pas savoir les gérer a un effet néfaste. La notion de conscience est ce qui permet de prendre soin de son mental. 
        </p>
        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
          Et c'est grâce à ça qu'on peut développer de l'<span className='bold'>empathie</span> aussi, pour soi-même et pour les autres : <span className='bold'>savoir dealer avec un état moins joyeux, ou savoir reconnaître et se défaire de pensées négatives</span>. <span className='gold underline'>Prendre de la distance d'avec des choses qui ne sont pas qui nous sommes, c'est aussi de là que naît l'humour et l'amour.</span>
          </p>
        </animated.div>
        <p>
        <br /><br />
        Ainsi, l'authenticité émotionnelle se révèle être un guide essentiel dans notre quête d'épanouissement 🌟. En nous permettant de voir et d'accepter nos émotions telles qu'elles sont, nous évitons le piège de la fausse positivité qui, loin de nous protéger, peut souvent aggraver nos souffrances en nous éloignant de solutions véritables et durables. <br /><br />
        
        <span className='underline gold'>La sagesse et la conscience</span>, lorsqu'elles sont cultivées, nous permettent de naviguer dans le spectre complet de nos émotions avec équilibre et intégrité. Elles nous encouragent à affronter plutôt qu'à fuir, à reconnaître nos peines et nos joies avec la même <span className='gold'>sincérité</span>. Cela signifie souvent d'oser examiner les aspects de notre vie qu'on préférerait ignorer, comme les peurs, les doutes, ou les incertitudes, et de <span className='underline'>les accepter comme parties intégrantes de notre expérience</span>.<br /><br />

        Cette conscience favorise une positivité qui n'est pas une simple façade, mais un état d'esprit robuste, capable de résilience lorsque confronté à des obstacles. Nous nous ouvrons ainsi à des possibilités de croissance personnelle 🌱 qui étaient autrement obstruées par le déni ou l'évitement. <br /><br />
        En la privilégiant plus que la simple volonté d'être positif, on transforme chaque défi en une chance de croître et de nous renforcer. Cela nous pousse à agir, non pas par peur ou par obligation, mais par un choix conscient et réfléchi, favorisant ainsi un bien-être profond.
        </p>

        <p><br /><br /></p>  
        <div className="button">
          <Link to="/formationCroissance" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre1Croissance;
