import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';

function Chapitre6Saimer() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();
  const [animationStyle5, ref5] = useAnimatedLine();
  const [animationStyle6, ref6] = useAnimatedLine();
  
  return (
      <>
        <h1 className='chapitre'>Chapitre 6 : le sens du bonheur </h1>

        <p>
          Notre âme n'a pas envie d'aider au travers d'un mental qui souhaite juste glousser : se revaloriser et se sentir important. C'est un comportement qui n'aide pas le monde du mieux qu'on pourrait. Or, aider fait partie intégrante de notre bonheur.<br /><br />
        </p>

        <p>On veut en réalité se reconnecter à l'intérieur, revenir à nos aspirations, et aider dans cette fluidité d'être, et d'agir à travers cette connexion et ce rayonnement qui vient de l'intérieur. <br /></p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
        <p className="animatable">
          Parfois, on a cette reprise de spontanéité, on revient à soi, et on se sent mieux dans cette façon d'opérer. 
          <br /><br />Et certaines personnes vont nous dire qu'on est égoïste, et que notre façon d'agir n'est pas juste, parfois prétextant qu'on est trop “libre”. Bref, on dérange. <br />On dérange parce que les autres voient au travers de notre changement et de notre reconnexion à nous-même, les fardeaux qu'eux-mêmes continuent à porter. 
          <br /><br />Nous n'en sommes pas responsables.
        </p>
        </animated.div>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
        <p className="animatable">
          Par exemple, l'égoïsme n'a rien à voir avec le fait qu'on rayonne dans nos projets de cœur. L'égoïsme, ça n'est pas non plus prendre soin de soi. Mais cette liberté qu'on peut avoir peut faire réagir les gens qui ne la prennent pas.
        </p>
        </animated.div>
        
        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">Qu'est-ce qu'aider les gens ? En vérité, on n'aide pas lorsqu'on ne s'aide pas soi-même.</p>
        </animated.div>
      
        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">Tout va de pair. Lorsqu'on offre un cadeau à une personne, ça nous fait plaisir de faire plaisir. Si ça ne nous fait pas plaisir, si c'est à contre-cœur, c'est que le cadeau n'est pas bon.</p>
        </animated.div>

        <animated.div className="animated-line" style={animationStyle5} ref={ref5}>
        <p className="animatable">
          Ça peut paraître bizarre comme phrase mais, si j'ai envie d'aider le monde et que je ne fais rien pour moi, alors je n'aide pas le monde. Ou encore, si je vis ma “meilleure” vie tout seul / sans penser aux autres, alors, je ne vis pas ma meilleure vie en vérité. Voir le film Into the Wild qui démontre bien ce dernier aspect.
        </p>
        </animated.div>

        <p>
          Comme on a pu le voir, le bonheur ne serait être parfait s'il n'est pas partagé.
          À l'inverse, le malheur sera à jamais que la conséquence d'une personne qui s'ignore
          dans sa nature, dans sa grandeur, et dans sa connexion à la vie.
        </p>

        <p>
        S'aimer, c'est savoir que dans les actions menées par l'ego, je n'acquiers en vérité aucun bonheur. Les mauvais plaisirs-satisfactions au travers d'actions égotiques ne sont que des illusions de montées dans les loopings d'une montagne russe menée par un mental-ego. Parfois, en tant que personnification, on peut l'appeler le "tyran" : c'est une façon de se rendre compte que ceci existe en tant que création et que cela nous inflige réellement nos soucis de vie.
        <br /><br />
        Il y a quelque chose qui est inestimable : c'est la paix à laquelle on peut accéder chaque jour dès l'instant où on lâche prise.<br />
        </p>

        <animated.div className="animated-line" style={animationStyle6} ref={ref6}>
        <p className="animatable">
          Un bonheur se reçoit et s'amplifie dans l'imprévu d'une rencontre, dans le rire d'un partage, dans l'étonnement d'un abandon. Quand je cherche à l'emprisonner dans la répétition ou l'exigence, il se dérobe et se perd à jamais.
          <br /><br />
          - Jacques Salomé
        </p>
        </animated.div>

        <p><br /><br />
        Lorsqu'on ne s'aime pas assez, ce qu'il se passe c'est que <b>nous devenons indifférent au bonheur qui existe en nous et autour de nous</b>. Ce sont comme des voiles qui nous empêchent de voir la simplicité avec laquelle
        celui-ci peut être vécu, avec les forces sous-jacentes qui l'accompagnent.
        Que faut-il pour accepter le bonheur présent intrinsèquement en nous ?<br /><br />
        <u>Il est nécessaire justement de s'aimer</u>. Pour cela, développer la gratitude pour ce que l'on vit est très utile, ainsi que cultiver ce que l'on aime : les relations, les hobbies, le travail qu'on aime, les efforts qu'on aime,
        l'activité physique, l'art, la nature... Et ainsi vivre au présent.<br /><br />
        La <b>gratitude</b> nous empêche de tourner la tête en arrière et, de ce fait, vivre dans le passé. Accepter le bonheur présent ne peut pas se faire si on évite nos propres ressentis.
        Souvent, nous avons tendance à vouloir éviter ou ignorer les émotions désagréables, pensant qu'elles nous empêchent d'être heureux. Cependant, en les refoulant ou en les niant, nous risquons de créer plus de tension et de souffrance. Au lieu de cela, en les accueillant et en les explorant, nous pouvons apprendre à les comprendre et surtout à les accepter, ce qui est une sagesse émotionnelle.
        Le bonheur n'est pas un état permanent exempt de toute "négativité", mais plutôt un équilibre entre les moments agréables et les défis de la vie, avec une appréciation profonde de l'instant présent.
        <br /><br /><br />
        </p>

        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre6Saimer;
