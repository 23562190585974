import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import LoadingGif from '../animations/loadingGif.js';

function Chapitre3Saimer() {
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 3 : Notre identité</h1>

        <p>
        Notre Ego est ce qui nous forge une personnalité et une sensation d'être particulier (d'être un individu). C'est une notion qui nous donne une conscience de notre individualité. Ce qui est utile : je ne suis pas toi, je suis moi. Ainsi, on a des qualités propres à un Ego sain : être capable de dire non, se valider soi-même, prendre soin de soi, etc. C'est bien sûr ce qui est en lien avec la confiance et l'estime de soi.
        </p>
        
        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
          De même, on peut aussi avoir des effets négatifs liés à une trop forte identification à l'Ego : l'égoïsme (ne penser qu'à soi), l'orgueil, l'isolement, l'arrogance, ou encore la volonté de dominer.
          </p>
        </animated.div>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
          Cette notion renvoie fortement à l'apparence (l'appât rance). Lorsqu'elle devient une priorité, nous en devenons esclave.
          </p>
        </animated.div>
        
        <LoadingGif
          src="/pictures/moiteacher2.gif"
          placeholder="/pictures/moiteacher2.png"
          alt="cours développement personnel"
        />
        <p>
          L'Ego est une structure, ni plus ni moins. C'est la façon dont on s'identifie à celui-ci qui nous réduit en tant qu'être. Car en vérité, nous sommes une essence qui s'incarne notamment dans le corps. Et ce corps, tout comme le mental, devient outil.
        </p>
        
        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="gold underline animatable">
          “Le mental intuitif est un don sacré et le mental rationnel est un serviteur fidèle. Nous avons créé une société qui honore le serviteur et a oublié le don.” 
          </p>
          <p>
            - Albert Einstein 
          </p>
        </animated.div>

        <p>
          Au travers de l'Ego, on aura pris l'habitude <span className="bold">de croire que le mental est celui qui est aux commandes alors qu'il est le serviteur</span>. Nous vivons dans une période où l'aisance de vie, nous a permis de reprendre conscience petit à petit que nous sommes des êtres avec plusieurs dimensions, c'est-à-dire que <span className="bold">nous avons plusieurs niveaux d'existence</span>. Pour représenter cela, d'une manière générale, on peut dire que nous sommes Esprit, mental, et corps. Ou encore, qu'on est esprit (mental), corps émotionnel, et corps physique. <br />
          Le but n'est pas d'être réducteur mais de comprendre. Par exemple, si on jette un coup d'oeil à la culture hindoue et chinoise, nous avons les 7 chakras principaux du corps énergétique, et ceux-ci renvoient chacun à un corps précis : on fait référence alors à 7 dimensions de l'être.<br /><br />
          Dans le sujet du deuil, un exemple parlant qui rend compte que nous sommes au-delà de l'égo, est le fait que tout lien d'attachement (haine, rancune, ...) s'envole après qu'une personne décède. Et bien souvent, ce qu'il reste est l'amour qu'on portait potentiellement pour la personne. 
          <br /><br />   
          Certaines personnes deviennent reconnues, ou plus célèbre qu'après leur mort, alors que, de leur vivant, tout un égrégore de jugements et de critiques pouvaient se lier à la personne. Pour ainsi dire, après la mort, l'âme est célébrée alors que le reste ne "pèse" plus vraiment dans la balance.<br />
        </p>

        <p>
          Au travers d'un blocage qu'on vit, on s'identifie toujours à quelque chose qu'on n'est pas. C'est un personnage et une histoire qu'on a eu l'habitude de nourrir à partir d'un trauma d'enfance.<br /><br />
          Le fait de souffrir d'un schéma réside dans le fait qu'on adhère à ce point à l'idée d'être ce personnage et donc que cette histoire soit la vérité. <br /><br />
          <br /><br />
        </p>

        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/tGNvRLXmlqI?rel=0" 
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="questionnement introspectif">
          </iframe>
        </div>

        <p><br /><br /></p>
        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>

      </>
  );
}

export default Chapitre3Saimer;
