import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import AudioPlayer from '../animations/audioPlayer.js';
import AnimatedH2 from '../animations/animatedH2.js';

function Chapitre7Secouter() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();
  const [animationStyle5, ref5] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 7 : Les pensées</h1>

        <h2 className="subsection-title">Le silence (se permettre de réguler ses pensées)</h2>

        <p>
          Dans notre société, nous avons pris l'habitude de nous hypnotiser avec le bruit. 
          On est en permanence bombardés par un flux d'informations rapides qui sollicitent grandement notre mental. C'est un fait qui, si on prend pas certaines mesures, peut nous amener à nous couper de ressentis et de perceptions subtiles qui nous définissent en tant qu'êtres humains sensibles.
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            Ce bruit peut aussi se manifester à travers un mental agité, saturé de pensées mal gérées et trop pesantes. 
            Un auteur, dont le nom m'échappe, disait : “La maladie de la société actuelle est le fait qu'on pense trop”.
          </p>
        </animated.div>

        <p>
          Nous sommes devenus accros à une sur-stimulation mentale, incapables de laisser notre esprit se poser. 
          Les réseaux sociaux, la surcharge d'informations, les exigences de la société moderne, 
          tout cela alimente ce dialogue intérieur constant et épuisant.
        </p>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            Si tu reconnais être trop pris dans ce type de fonctionnement, une bonne première chose est de se permettre de ralentir. 
            Ralentir signifie couper court à l'urgence que l'on se crée inconsciemment. 
            Cela peut être aussi simple que de s'accorder plusieurs fois par jour de véritables pauses, 
            sans téléphone, parfois sans tâche à accomplir, juste être présent.
          </p>
        </animated.div>

        <p>
          Ce ralentissement aide à réguler l'afflux des pensées et crée de l'espace pour le silence intérieur. 
          C'est dans ce silence que nous pouvons accéder à une plus grande clarté mentale.
        </p>

        <AnimatedH2 className="subsection-title">Être observateur, sans jugement</AnimatedH2>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
            Un excès de “bruit” (de pensées) à l'intérieur peut aussi être lié à des émotions non exprimées ou mal gérées. 
            Nos pensées sont influencées par nos états émotionnels. Par exemple, de l'anxiété, de la colère, ou de la tristesse non conscientisée 
            vont se manifester sous forme de pensées répétitives et lourdes.
          </p>
        </animated.div>

        <p>
          Le jugement est une attitude, un réflexe qui survient comme pour se sécuriser. En vérité, le jugement est souvent une manière de 
          se protéger de ce que l'on perçoit comme une menace. Lorsqu'on juge, on essaie de prendre le contrôle sur une situation, une personne, ou même sur soi-même.
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
            Lorsqu'on parvient à observer sans jugement, on obtient une perspective intéressante. 
            Observer sans juger signifie accueillir les expériences, les émotions et les pensées pour ce qu'elles sont, 
            sans chercher à les catégoriser immédiatement comme "bonnes" ou "mauvaises".
          </p>
        </animated.div>

        <p>
          Cette capacité à observer sans juger nous permet aussi de faire la paix avec nos imperfections et celles des autres. 
          C'est un acte de bienveillance envers soi, un moyen de relâcher cette pression constante de devoir tout maîtriser, tout comprendre. 
          Cela ne veut pas dire devenir passif face aux événements, mais plutôt apprendre à s'adapter, à se laisser traverser par la vie sans s'y opposer en permanence.
        </p>

        <animated.div className="animated-line" style={animationStyle5} ref={ref5}>
          <p className="animatable">
            En faisant cela, on accède à un autre niveau de lucidité, où même l'auto-dérision, et plus globalement l'humour, peuvent prendre leur place avec amour.
          </p>
        </animated.div>

        <p className="bold">
          <em>Audio 3 :</em>
        </p>

        {/* Lecteur audio intégré */}
        <AudioPlayer Url="audios/audio3.mp3" />

        <p><br /><br /></p>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre7Secouter;
