import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';

function Chapitre6Secouter() {
  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 6 : Suivre le fil d'or, se relier à son intuition</h1>

        <p>
          Il y a des personnes sur Terre qui se sentent tellement légitimes de faire des choses ignobles ou relativement sales. 
          C'est un exemple qui m'a interpellé par rapport à ce sujet et qui me fait dire que ces personnes n'ont simplement pas conscience de leur acte. 
          Mais alors qu'est-ce que se sentir légitime ? C'est une question importante car le simple fait de suivre son intuition est un défi pour la confiance et cette question de légitimité.
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            Certaines personnes l'associent à un droit que l'on s'octroie car on a peut-être été validé par l'extérieur par exemple. 
            Elles agissent avec assurance, même sans avoir nécessairement les compétences ou l'expérience pour justifier cette confiance en elles. 
            À l'inverse, d'autres, qui ont pourtant cette connexion en elle, et cette expérience, doutent sans cesse de leur légitimité.
          </p>
        </animated.div>

        <p>
          Comment expliquer que ceux qui se sentent le moins légitimes sont parfois les plus compétents, 
          tandis que d'autres, sans la même réelle compétence, avancent sans hésitation ?
        </p>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            Ce n'est pas seulement une question de compétence et d'expérience. La légitimité est aussi une question de perception, 
            de l'image que l'on a de soi-même et de la permission que l'on se donne pour agir. 
            Beaucoup se sentent prisonniers, pensant qu'il leur manque toujours quelque chose avant de pouvoir se sentir à la hauteur.
          </p>
        </animated.div>

        <p>
          Mais au-delà de se prouver à soi-même ou aux autres qu'on est capable, c'est aussi une question de <span className="gold">connexion à soi</span>. 
          Suivre son intuition demande de la confiance, et c'est là que réside une part du défi. 
          Le <span className="gold">“fil d'or intérieur”</span>, j'appelle ainsi le chemin intuitif qui nous guide vers ce qui résonne profondément en nous. 
          En le poursuivant, nous agissons en alignement avec ce que nous sommes vraiment, plutôt que de chercher à correspondre à une image extérieure de succès ou de perfection.
        </p>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
            Suivre ce fil d'or, c'est accepter d'expérimenter sachant que la perfection qu'on attend nous attend, 
            et que le moment idéal pour se sentir légitime n'arrivera peut-être jamais, car il n'est pas question de perfection mais d'écoute et d'action.
          </p>
        </animated.div>

        <p>
          La légitimité véritable vient de l'intérieur: de cette capacité à s'autoriser à être soi-même, à faire des erreurs, à apprendre en chemin, 
          et à reconnaître que notre intuition a sa propre sagesse, même si elle défie parfois nos peurs et nos doutes.
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
            Le défi concernant notre intuition, c'est d'apprendre à se faire confiance, non pas parce que l'on a tout prouvé ou tout maîtrisé, 
            mais parce que l'on sait, au fond de soi, que l'on suit à chaque instant un chemin qui nous correspond et qui résonne en nous.
          </p>
        </animated.div>

        <p>
          Et c'est ce même chemin qui nous guide, car nous sommes capables de percevoir au travers des éléments de notre vie, dans chaque instant, 
          des signes qui nous informent, reflétant nos questionnements et par la même les solutions dont on a tant besoin pour poursuivre nos actions.<br /><br /><br /><br />
        </p>


        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre6Secouter;
