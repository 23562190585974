import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import Connexion from './PanelConnexion.js';
import withAuth from './withAuth.js';
import NewsletterPage from './fleur/newsletter.js';
import Verification from './Verification.js';
import ValidationEmail from './Validation_email.js';
import './style.css';
import Loading from './loading.js';
import PrivateRoute from './PrivateRoute.js';

import ListeCours from './ListeCours.js';
import Profile from './Profile.js';
import FormationSaimer from './FormationSaimer.js';
import IntroductionSaimer from './formationSaimer/introduction.js';
import Chapitre1Saimer from './formationSaimer/chapitre1.js';
import Chapitre2Saimer from './formationSaimer/chapitre2.js';
import Chapitre3Saimer from './formationSaimer/chapitre3.js';
import Chapitre4Saimer from './formationSaimer/chapitre4.js';
import Chapitre5Saimer from './formationSaimer/chapitre5.js';
import Chapitre6Saimer from './formationSaimer/chapitre6.js';
import Chapitre7Saimer from './formationSaimer/chapitre7.js';
import Chapitre8Saimer from './formationSaimer/chapitre8.js';
import Chapitre9Saimer from './formationSaimer/chapitre9.js';
import Chapitre10Saimer from './formationSaimer/chapitre10.js';
import Chapitre11Saimer from './formationSaimer/chapitre11.js';
import Chapitre12Saimer from './formationSaimer/chapitre12.js';

import FormationSecouter from './FormationSecouter.js';
import Chapitre1Secouter from './formationSecouter/chapitre1.js';
import Chapitre2Secouter from './formationSecouter/chapitre2.js';
import Chapitre3Secouter from './formationSecouter/chapitre3.js';
import Chapitre4Secouter from './formationSecouter/chapitre4.js';
import Chapitre5Secouter from './formationSecouter/chapitre5.js';
import Chapitre6Secouter from './formationSecouter/chapitre6.js';
import Chapitre7Secouter from './formationSecouter/chapitre7.js';
import Chapitre8Secouter from './formationSecouter/chapitre8.js';
import Chapitre9Secouter from './formationSecouter/chapitre9.js';
import Chapitre10Secouter from './formationSecouter/chapitre10.js';
import Chapitre11Secouter from './formationSecouter/chapitre11.js';
import Chapitre12Secouter from './formationSecouter/chapitre12.js';

import FormationCroissance from './FormationCroissance.js';
import Chapitre1Croissance from './formationCroissance/chapitre1.js';
import Chapitre2Croissance from './formationCroissance/chapitre2.js';
import Chapitre3Croissance from './formationCroissance/chapitre3.js';
import Chapitre4Croissance from './formationCroissance/chapitre4.js';
import Chapitre5Croissance from './formationCroissance/chapitre5.js';

import DisplayWrapper from './DisplayWrapper.js';
import 'react-toastify/dist/ReactToastify.css';

import { useUI } from './UIContext.js';
import { useAuth } from './AuthContext.js';

import HomePage from './HomePage.js';
const MentionsLegales = React.lazy(() => import('./MentionsLegales.js'));
const Cgv = React.lazy(() => import('./CGV.js'));
const Cgu = React.lazy(() => import('./CGU.js'));

const TitleAndDescription = () => {
  const location = useLocation();
  const { updateTitle, updateMetaDescription, updateSocialMeta } = useAuth();

  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      updateTitle("Flowgraines - plateforme de cours de développement personnel");
      updateMetaDescription("Découvrez des cours de développement personnel profonds et améliorez les fondements de votre vie.");
      updateSocialMeta("Flowgraines - plateforme de cours de développement personnel", "Découvrez des cours de développement personnel profonds et améliorez les fondements de votre vie.", "https://flowgraines.fr/pictures/Flowgraines.png");
    } else if (path.startsWith('/formationSaimer')) {
      updateTitle("Flowgraines - Cours S'aimer soi-même");
      updateMetaDescription("Participez à notre cours pour apprendre à s'aimer et trouver le bonheur intérieur.");
      updateSocialMeta("Flowgraines - Cours S'aimer soi-même", "Participez à notre cours pour apprendre à s'aimer soi-même et trouver le bonheur intérieur.", "https://flowgraines.fr/pictures/Flowgraines.png");
    } else if (path.startsWith('/formationCroissance')) {
      updateTitle("Flowgraines - Cours Croissance Personnelle");
      updateMetaDescription("Explorez le cours sur la croissance personnelle et redécouvrez votre potentiel.");
      updateSocialMeta("Flowgraines - Cours Croissance Personnelle", "Explorez le cours sur la croissance personnelle et redécouvrez votre potentiel.", "https://flowgraines.fr/pictures/Flowgraines.png");
    } 
    else if (path.startsWith('/formationSecouter')) {
      updateTitle("Flowgraines - Cours Écouter la vie en soi");
      updateMetaDescription("Explorez le cours sur l'écoute de soi et reconnectez avec votre intuition.");
      updateSocialMeta("Flowgraines - Cours Écouter la vie en soi", "Explorez le cours sur l'écoute de soi et reconnectez avec votre intuition.", "https://flowgraines.fr/pictures/Flowgraines.png");
    }
    else {
      updateTitle("Flowgraines - Open your heart and your mind");
      updateMetaDescription("Rejoignez Flowgraines pour ouvrir votre cœur et votre esprit à de nouvelles possibilités.");
      updateSocialMeta("Flowgraines - Open your heart and your mind", "Rejoignez Flowgraines pour ouvrir votre cœur et votre esprit à de nouvelles possibilités.", "https://flowgraines.fr/pictures/Flowgraines.png");
    }
  }, [updateTitle, updateMetaDescription, updateSocialMeta, location]);
};

function App() {
  const { currentUser, userData, loadUserData } = useAuth();
  const { isLoginModalOpen } = useUI();
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);

  useEffect(() => {
    if (currentUser && !userData) {
      loadUserData(currentUser.uid).catch(error => {
        console.error("Erreur lors du chargement des données utilisateur", error);
      });
    }
  }, [currentUser, userData, loadUserData]);

  useEffect(() => {
    // Simule un chargement pour le DisplayWrapper (ex. : images, effets)
    const timer = setTimeout(() => setIsGlobalLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  if (isGlobalLoading) {
    return <Loading />; // Chargement global
  }

  const ProtectedFormationSaimer = withAuth(FormationSaimer);
  const ProtectedIntroductionSaimer = withAuth(IntroductionSaimer);
  const ProtectedChapitre1Saimer = withAuth(Chapitre1Saimer);
  const ProtectedChapitre2Saimer = withAuth(Chapitre2Saimer);
  const ProtectedChapitre3Saimer = withAuth(Chapitre3Saimer);
  const ProtectedChapitre4Saimer = withAuth(Chapitre4Saimer);
  const ProtectedChapitre5Saimer = withAuth(Chapitre5Saimer);
  const ProtectedChapitre6Saimer = withAuth(Chapitre6Saimer);
  const ProtectedChapitre7Saimer = withAuth(Chapitre7Saimer);
  const ProtectedChapitre8Saimer = withAuth(Chapitre8Saimer);
  const ProtectedChapitre9Saimer = withAuth(Chapitre9Saimer);
  const ProtectedChapitre10Saimer = withAuth(Chapitre10Saimer);
  const ProtectedChapitre11Saimer = withAuth(Chapitre11Saimer);
  const ProtectedChapitre12Saimer = withAuth(Chapitre12Saimer);

  const ProtectedFormationSecouter = withAuth(FormationSecouter);
  const ProtectedChapitre1Secouter = withAuth(Chapitre1Secouter);
  const ProtectedChapitre2Secouter = withAuth(Chapitre2Secouter);
  const ProtectedChapitre3Secouter = withAuth(Chapitre3Secouter);
  const ProtectedChapitre4Secouter = withAuth(Chapitre4Secouter);
  const ProtectedChapitre5Secouter = withAuth(Chapitre5Secouter);
  const ProtectedChapitre6Secouter = withAuth(Chapitre6Secouter);
  const ProtectedChapitre7Secouter = withAuth(Chapitre7Secouter);
  const ProtectedChapitre8Secouter = withAuth(Chapitre8Secouter);
  const ProtectedChapitre9Secouter = withAuth(Chapitre9Secouter);
  const ProtectedChapitre10Secouter = withAuth(Chapitre10Secouter);
  const ProtectedChapitre11Secouter = withAuth(Chapitre11Secouter);
  const ProtectedChapitre12Secouter = withAuth(Chapitre12Secouter);

  const ProtectedVerification = withAuth(Verification);
  const ProtectedProfile = withAuth(Profile);

  return ( 
    <BrowserRouter>
      <TitleAndDescription />
      {isLoginModalOpen && <Connexion />}

      <Suspense fallback={<Loading />}>
        <DisplayWrapper>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/fleur/newsletter"
            element={
              <PrivateRoute>
                <NewsletterPage />
              </PrivateRoute>
            }
            />
            <Route path="/profile" element={<ProtectedProfile />} />
            <Route path="/cours" element={<ListeCours />} />
            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="/conditions-generales-de-vente" element={<Cgv />} />
            <Route path="/conditions-generales-d-utilisation" element={<Cgu />} />

            <Route path="/validation_email" element={<ValidationEmail />} />
            <Route path="/verification" element={<ProtectedVerification />} />
            
            <Route path="/formationSaimer" element={<ProtectedFormationSaimer />} />
            <Route path="/formationSaimer/introduction" element={<ProtectedIntroductionSaimer />} />
            <Route path="/formationSaimer/chapitre1" element={<ProtectedChapitre1Saimer />} />
            <Route path="/formationSaimer/chapitre2" element={<ProtectedChapitre2Saimer />} />
            <Route path="/formationSaimer/chapitre3" element={<ProtectedChapitre3Saimer />} />
            <Route path="/formationSaimer/chapitre4" element={<ProtectedChapitre4Saimer />} />
            <Route path="/formationSaimer/chapitre5" element={<ProtectedChapitre5Saimer />} />
            <Route path="/formationSaimer/chapitre6" element={<ProtectedChapitre6Saimer />} />
            <Route path="/formationSaimer/chapitre7" element={<ProtectedChapitre7Saimer />} />
            <Route path="/formationSaimer/chapitre8" element={<ProtectedChapitre8Saimer />} />
            <Route path="/formationSaimer/chapitre9" element={<ProtectedChapitre9Saimer />} />
            <Route path="/formationSaimer/chapitre10" element={<ProtectedChapitre10Saimer />} />
            <Route path="/formationSaimer/chapitre11" element={<ProtectedChapitre11Saimer />} />
            <Route path="/formationSaimer/chapitre12" element={<ProtectedChapitre12Saimer />} />

            <Route path="/formationSecouter" element={<ProtectedFormationSecouter />} />
            <Route path="/formationSecouter/chapitre1" element={<ProtectedChapitre1Secouter />} />
            <Route path="/formationSecouter/chapitre2" element={<ProtectedChapitre2Secouter />} />
            <Route path="/formationSecouter/chapitre3" element={<ProtectedChapitre3Secouter />} />
            <Route path="/formationSecouter/chapitre4" element={<ProtectedChapitre4Secouter />} />
            <Route path="/formationSecouter/chapitre5" element={<ProtectedChapitre5Secouter />} />
            <Route path="/formationSecouter/chapitre6" element={<ProtectedChapitre6Secouter />} />
            <Route path="/formationSecouter/chapitre7" element={<ProtectedChapitre7Secouter />} />
            <Route path="/formationSecouter/chapitre8" element={<ProtectedChapitre8Secouter />} />
            <Route path="/formationSecouter/chapitre9" element={<ProtectedChapitre9Secouter />} />
            <Route path="/formationSecouter/chapitre10" element={<ProtectedChapitre10Secouter />} />
            <Route path="/formationSecouter/chapitre11" element={<ProtectedChapitre11Secouter />} />
            <Route path="/formationSecouter/chapitre12" element={<ProtectedChapitre12Secouter />} />

            <Route path="/formationCroissance" element={<FormationCroissance />} />
            <Route path="/formationCroissance/chapitre1" element={<Chapitre1Croissance />} />
            <Route path="/formationCroissance/chapitre2" element={<Chapitre2Croissance />} />
            <Route path="/formationCroissance/chapitre3" element={<Chapitre3Croissance />} />
            <Route path="/formationCroissance/chapitre4" element={<Chapitre4Croissance />} />
            <Route path="/formationCroissance/chapitre5" element={<Chapitre5Croissance />} />
          </Routes>
        </DisplayWrapper>
      </Suspense>
      
    </BrowserRouter>
  );
}

export default App;
