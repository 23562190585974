import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';

function Chapitre2Secouter() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 2 : La vulnérabilité, se reconnecter à soi</h1>

        <p>
          En essayant d'être cette personne qu'on rêve d'être, on en arrive souvent à une sorte de non-lâcher-prise face à une envie de changement. 
          La <span className="gold">spontanéité</span> est présente particulièrement lors de ce fameux lâcher-prise, il y a donc dans l'ambition-envie, un pendant négatif et contre-productif.
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            Essayant d'être <span className="bold">“parfait”</span> et résistant à nos émotions, on s'éloigne de notre <span className="bold">authenticité </span> 
            et de quelque chose d'autre à la fois : notre vulnérabilité.
          </p>
        </animated.div>

        <p>
          La <span className="gold">vulnérabilité</span> c'est un facteur connexion pour soi-même et pour les autres aussi.
        </p>
          
        <p>
          C'est en elle que réside une certaine force, contraire à un quelconque attachement à l'ego (orgueil, trop forte fierté...). 
          Plus je suis dans un attachement à l'ego, plus je vais empêcher cette ouverture et cette force qu'est la vulnérabilité.
        </p>

        <p>
          Bien qu'elle puisse être perçue comme une faiblesse, elle est en fait une part de <span className="gold">l'expérience émotionnelle</span>. 
          En l'acceptant, nous nous ouvrons à une meilleure compréhension de nous-mêmes et nous établissons certainement des liens plus authentiques avec les autres.
        </p>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            La vulnérabilité nous permet de nous connecter à des strates émotionnelles qu'on n'a pas pu atteindre jusqu'à maintenant. 
            De même, elle nous permet de percevoir nos peurs, nos désirs et nos besoins. 
            C'est en reconnaissant et en partageant ces aspects de soi qu'on peut établir de <span className="bold underline">vraies relations profondes</span>.
            Lorsque nous osons être vulnérables, <span className='bold'>nous invitons les autres à faire de même</span>, créant ainsi un espace de confiance et d'empathie mutuelle.
          </p>
        </animated.div>

        <h3 className="gold">
          En embrassant notre vulnérabilité, nous nous autorisons à ressentir pleinement, à être <span className="bold">honnêtes</span> avec nous-mêmes 
          et à accepter nos imperfections.
        </h3>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
            Loin d'être un signe de faiblesse, la vulnérabilité exige du <span className="bold gold">courage</span> et de l'<span className="bold gold">honnêteté</span>. 
            Elle implique de laisser tomber les masques que nous portons parfois pour nous sécuriser.
          </p>
        </animated.div>

        <p>
          En accueillant ces parties de toi, qui te semblent faibles ou insécurisantes, avec bienveillance plutôt qu'en les rejetant, 
          tu t'offres une forme de sécurité qui vient de l'intérieur. Car tu te montres à toi-même que tu es capable de t'aimer même à travers ces faiblesses. 
          Tu te montres que tu sais t'offrir la <span className="bold">bienveillance</span> et que tu peux naviguer sur ces moments émotionnels ou de souffrance en te parentant 
          (ok ça n'existe pas mais, je veux dire “être un parent pour soi-même”), et en étant un soutien juste pour toi-même.<br /><br /><br /><br /><br />
        </p>

        <div className='bordure-module'>
          <div className="module-container-wborder">
            <iframe
              src="/compoH5P/quizz_emotions_cachees.html"
              className="responsive-iframe"
              title="Quizz flowgraines ressenti"
            ></iframe>
          </div>
        </div>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre2Secouter;
