import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext.js';

const CheckoutForm = ({ onSuccessfulCheckout, programme, onClose }) => {
  const { currentUser, userData, loadUserData } = useAuth();

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#32325d',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
      },
    },
  };

  useEffect(() => {
    if (currentUser && (!userData || !userData.email)) {
      loadUserData(currentUser.uid).catch(error => {
        console.error("Erreur lors du chargement des données utilisateur", error);
      });
    }
  }, [currentUser, userData, loadUserData]);
  
  const stripe = useStripe();
  const elements = useElements();

  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);  
  const [price, setPrice] = useState('');
  const [isConnectedMessage, setIsConnectedMessage] = useState('');

  const navigate = useNavigate();
  
  const API_URLS = {
    getCourse: process.env.REACT_APP_FIREBASE_FUNCTION_API_URL,
    subscription: process.env.REACT_APP_FIREBASE_FUNCTION_APISUB_URL,
  };

  const messageSuccessCourse = "Paiement réussi. Votre programme est disponible.";
  const messageSuccessSub = "Paiement réussi. Vous êtes maintenant abonné(e).";

  const handleSuccess = (paymentIntent, paymentType) => {
    const clientSecret = paymentIntent.clientSecret;
    if (clientSecret) {
      setPaymentStatus(
        paymentType === 'getCourse' ? messageSuccessCourse : messageSuccessSub
      );
      navigate(`/verification?payment_intent_client_secret=${clientSecret}`);
    } else {
      console.error('Client Secret manquant.');
      setPaymentStatus('Une erreur est survenue. Veuillez réessayer.');
    }
    onSuccessfulCheckout();
  };

  // Gérer les appels API
  const fetchAPI = async (url, body) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      let errorMsg = `Erreur lors de l'appel API`;
      try {
        const errorBody = await response.json();
        errorMsg = errorBody.message || errorMsg;
        console.error("Erreur API :", errorBody);
      } catch {
        errorMsg += `. Impossible de parser la réponse d'erreur.`;
      }
      throw new Error(errorMsg);
    }

    return response.json();
  };

  // Fonction principale : handleSubmit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setPaymentStatus("Stripe n'est pas chargé correctement.");
      return;
    }

    if (!currentUser || !userData || !userData.email || !userData.emailVerified) {
      setIsConnectedMessage("Veuillez vous reconnecter ou faire revérifier votre mail.");
      return;
    }

    setIsSubmitting(true);
    setPaymentStatus('');

    try {
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod: createdPaymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        throw new Error(`${error.message}`);
      }

      let apiUrl;
      let requestBody;

      if (paymentType === 'getCourse') {
        apiUrl = API_URLS[paymentType];
        requestBody = {
          paymentMethodId: createdPaymentMethod.id,
          programmeId: programme.id,
          userId: currentUser.uid,
          email: userData.email,
        };
      } else if (paymentType === 'subscription') {
        apiUrl = API_URLS[paymentType];
        requestBody = {
          paymentMethodId: createdPaymentMethod.id,
          userId: currentUser.uid,
          email: userData.email,
        };
      } else {
        throw new Error("Méthode de paiement invalide.");
      }

      // Appel API pour le paiement ou l'abonnement
      const apiResult = await fetchAPI(apiUrl, requestBody);

      if (!apiResult || !apiResult.clientSecret) {
        throw new Error("La réponse de l'API est invalide ou incomplète.");
      }

      // Vérifier si le PaymentIntent est déjà confirmé
      if (apiResult.status === 'succeeded') {
        handleSuccess(apiResult, paymentType);
        return;
      }

      if (apiResult.status === 'requires_action') {
        const { paymentIntent, error: stripeError } = await stripe.confirmCardPayment(apiResult.clientSecret);
    
        if (stripeError) {
          throw new Error(`Erreur de confirmation Stripe : ${stripeError.message}`);
        }
    
        if (paymentIntent.status === 'succeeded') {
          handleSuccess(paymentIntent, paymentType);
          return;
        } else {
          throw new Error("Le paiement n'a pas pu être finalisé après l'authentification.");
        }
      }

      if (apiResult.status === 'requires_payment_method') {
        setPaymentStatus("Le paiement a échoué. Veuillez vérifier votre carte.");
        return;
      }
      
      if (apiResult.status === 'incomplete') {
        setPaymentStatus("Votre abonnement est en attente. Veuillez contacter le support.");
        return;
      }
      // Si le statut est inattendu
      throw new Error("Statut inattendu du paiement. Veuillez réessayer.");
    } catch (error) {
      const errorMessage = error.message || "Une erreur inconnue est survenue.";
      setPaymentStatus(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="overlay-checkout"></div>
      <div className="form-checkout">
        <button onClick={onClose} className="close-button">
          <img src="/pictures/close_icon.png" alt="Fermer" />
        </button>
        <form onSubmit={handleSubmit}>
          {isConnectedMessage && <p>{isConnectedMessage}</p>}
          <p className='title'>{programme.title}</p>
          {programme.subtitle && (
            <p className="subtitle">{programme.subtitle}</p>
          )}
          <p>
          <Link to="/#aboutme" style={{ textDecoration: 'none' }} className="styled-link"> <i className="fas fa-caret-right"></i> Qui suis-je ?</Link>
          <br />
          <Link to="/#about" style={{ textDecoration: 'none' }} className="styled-link"> <i className="fas fa-caret-right"></i> Que vais-je apprendre avec ces programmes ?</Link> 
          <br />
          </p>
          {/* Affichage conditionnel du prix */}
          {paymentType && (
            <p>
              <span className="price-text">
              {price}
              <br />
              </span>
              {paymentType === 'subscription' && (
                <span className="access-text">
                  {' '}Accès mensuel au site + groupe en ligne
                </span>
              )}
              {paymentType === 'getCourse' && (
                <span className="access-text">
                  {' '}Accès au cours à vie + groupe en ligne
                </span>
              )}
            </p>
          )}
          <div className="payment-method-selector">
            <label className={`radio-option ${paymentType === 'getCourse' ? 'selected' : ''}`}>
              <input
                type="radio"
                value="getCourse"
                checked={paymentType === 'getCourse'}
                onChange={() => {
                  setPaymentType('getCourse');
                  setPrice(`${programme.price} € TTC`);
                  setPaymentStatus('');
                }}
              />
              Obtenir le cours
            </label>
            <label className={`radio-option ${paymentType === 'subscription' ? 'selected' : ''}`}>
              <input
                type="radio"
                value="subscription"
                checked={paymentType === 'subscription'}
                onChange={() => {
                  setPaymentType('subscription');
                  setPrice('19 euros par mois TTC');
                  setPaymentStatus('');
                }}
              />
              Accès mensuel
            </label>
          </div>
          <p></p>
          <div className="payment-details">
            {paymentType === 'getCourse' && (
              <div className="subscription-summary">
                <p><strong>Nom :</strong> {userData?.nom} <strong>Prénom :</strong> {userData?.prenom}</p>
                <div className="card-details">
                  <div className="input-wrapper">
                    <div className="card-element-container">
                      <CardElement options={cardElementOptions} />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {paymentType === 'subscription' && (
              <div className="subscription-summary">
                <p>
                  En cliquant sur "Payer", vous acceptez de souscrire à un abonnement mensuel.
                  Vous pourrez annuler à tout moment via la page Mon profile.
                </p>
                <p><strong>Nom :</strong> {userData?.nom} <strong>Prénom :</strong> {userData?.prenom}</p>
                <div className="card-details">
                  <div className="input-wrapper">
                    <div className="card-element-container">
                      <CardElement options={cardElementOptions} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <p></p>
          <button type="submit" disabled={!stripe || isSubmitting} className="payment-submit">
            {isSubmitting ? 'Traitement...' : 'Payer'}
          </button>
          {paymentStatus && <div className="payment-status">{paymentStatus}</div>}
        </form>
      </div>
    </>
  );
};

export default CheckoutForm;
