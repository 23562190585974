// components/AnimatedH2.js
import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const AnimatedH2 = ({ children, className }) => {
  // Utilisation de useInView pour détecter quand l'élément entre dans le champ de vision
  const { ref, inView } = useInView();
  
  // Animation de style appliquée lorsque le composant est visible
  const animationStyle = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0px)' : 'translateY(35px)',
    config: {  mass: 2, tension: 250, friction: 25 }, // Contrôle de la vitesse de l'animation
  });

  return (
    <animated.h2 className={className} style={animationStyle} ref={ref}>
      {children}
    </animated.h2>
  );
};

export default AnimatedH2;
