import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import GiphyEmbed from './animations/giphyEmbed.js'
import { InstagramEmbed } from 'react-social-media-embed';
import NewsletterModal from './NewsletterModal.js';
import { db } from './donnees/firebase.js';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { toast } from 'react-toastify';

function HomePage() {
  const Src = "/pictures/profile.jpg";
  const [isNewsletterModalOpen, setNewsletterModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  const WARRIOR_KEY = process.env.REACT_APP_FUNCTION_SEND_EBOOK_KEY;
  const location = useLocation();
  const navigate = useNavigate();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 100);
  
    return () => clearTimeout(timer); // Nettoyage
  }, []);

  // inscription newsletter
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('newsletter') === 'true') {
      setNewsletterModalOpen(true);
    }
  }, [location]);

  const sendEbook = async (email, pseudo) => {
    try {
      const response = await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_SEND_EBOOK_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": WARRIOR_KEY,
        },
        body: JSON.stringify({
          email,
          prenom: pseudo,
        }),
      });

      if (response.ok) {
        return {
          success: true,
          message: `${pseudo}, ton inscription est réussie ! L'ebook est en route vers ta boîte mail.`,
        };
      } else {
        const errorResult = await response.json();
        console.error("Erreur lors de l'envoi de l'email :", errorResult);
        return {
          success: false,
          message: "Inscription réussie, mais l'envoi de l'email a échoué. Merci de nous contacter.",
        };
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi :", error);
      throw error;
    } 
  };

  const handleSubscribe = async (data) => {
    const { email, pseudo } = data;

    if (!email || !pseudo)
    {
      return;
    }

    try {
      const emailDocRef = doc(db, 'emails', email); // Utilise l'email comme ID unique
      const docSnapshot = await getDoc(emailDocRef); // Vérifie si le document existe déjà

      if (docSnapshot.exists()) {
        return { success: false, message: 'Cet email est déjà inscrit.' };
      }
      else
      {
        await setDoc(emailDocRef, {
          pseudo,
          createdAt: new Date(),
        });
      }
    } catch (error) {
      console.error("Erreur lors de l'inscription :", error);
      toast.error("Une erreur est survenue lors de l'inscription. Veuillez réessayer.", {
        theme: "colored",
      });
    }
    return await sendEbook(email, pseudo);
  };

  const closeModal = () => {
    setNewsletterModalOpen(false);
    navigate(location.pathname, { replace: true });
  };

  return (
      <>
        <NewsletterModal
          isOpen={isNewsletterModalOpen}
          onClose={closeModal}
          onSubscribe={handleSubscribe}
        />

        {/*<YouTubeVideo videoId="jrlUCrDezt8" />
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/jrlUCrDezt8?rel=0" 
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="présentation flowgraines">
          </iframe>
        </div>*/}

        <p id="aboutme" style={{ flex: 1 }}>
          <br /><br />
          <b>Bienvenue sur Flowgraines, la plateforme dédiée à votre épanouissement</b>.
          <br /><br />
          <img src={Src} alt="Aurélien B." className="photo" /> 
          Je m'appelle Aurélien Bessonnet. Depuis plusieurs années, je partage à propos de nombreux sujets dont : <b>la nature Humaine</b>, <b>les émotions</b>, notre fonctionnement d'une manière générale, ou encore <b>l'intuition</b> et la créativité.
          Après un DUT en informatique, j'ai souhaité poursuivre mon apprentissage en autodidacte car la pédagogie de l'éducation française ne me convenait pas. Depuis longtemps, j'explorais par moi-même le développement personnel et le fonctionnement humain.
          J'ai bifurqué pour être Graphiste et Artiste, la création étant pour moi essentielle. <br />
          Je me suis rendu compte que ce qui m'importe réellement est <b>la transmission de connaissances</b>. Et aujourd'hui, je mélange tous ces centres d'intérêts pour délivrer des messages et des connaissances sur Nous dans l'objectif de nous permettre de nous voir "dans le bon sens".
          <br /><br />
          Cet espace a été créé dans le but de vous transmettre des graines de sagesse pour vous diriger vers <u>votre propre Vous</u>, votre propre demeure intérieure. Vous trouverez ici des cours en ligne sur de nombreux sujets tels que :
          <br /><br />
          <span className="bold underline">S'aimer Soi-même :</span> j'ai conçu un cours pour vous aider à re-découvrir ce qu'est l'Amour. Apprenez à renforcer votre empathie et votre estime de vous : voyagez au travers des textes et des animations qui vous guideront dans les étapes pour vous construire une image positive de vous-même. 
          Réapprenez à révéler votre vraie valeur et votre potentiel.
          <br /><br />
          <span className="bold underline">La Conscience des Émotions :</span> Apprenez à identifier, comprendre et gérer vos émotions pour une vie plus équilibrée et saine. Je vous propose une manière originale d'aborder vos émotions. 
          <br /><br />
          Chaque cours est conçu avec soin et originalité pour vous partager des enseignements riches.
          <br /><br />
          Embarquez avec nous dans ce voyage inspirant pour transformer votre perception du monde… et votre vie !<br />
          Rejoignez un groupe pour échanger et s'entraider dans la bonne humeur.
          <br />
        </p>

        <p className='gold bold citation'>
          Si tu laisses ton pouvoir de décision à la vie, les choses se feront par défaut.
          Sans toi, il n'y a pas le sens. Le sens de ta vie, c'est toi qui le donne.<br /><br />
        </p>

        <p className="clickable-text" onClick={toggleVisibility}>
        Vous avec la sensation qu'un cours basé sur du texte est ennuyeux ?
        </p>
        <div
          ref={contentRef}
          className={`hidden-text ${isVisible ? "visible" : ""}`}
          style={{
            maxHeight: isVisible
              ? `${contentRef.current?.scrollHeight}px`
              : "0px",
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out",
          }}
        >
        Je comprends que cela puisse rebuter à première vue, mais, et si vous passiez à côté de révélations capables de transformer votre quotidien ? Des prises de conscience susceptibles de bouleverser votre vie ?
        Et si vous étiez sur le point de dire oui à un cours qui pourrait vous offrir LE déclic tant attendu, celui qui vous permettra de révéler un potentiel latent ?
        </div>
        <p></p>
        <InstagramEmbed url="https://www.instagram.com/oretlien_art/p/C-8C2G0MnTq/" width={328} />

        <p id="about">
          J'ai eu l'élan de créer ce site pour aider les personnes qui ressentent avoir besoin de plus de clarté à propos de leurs questionnements et leurs défis de vie. Parfois, il suffit d'un <em>changement de perception</em> pour <b>faciliter</b> grandement la manière dont on vit les choses. <br /><br />
          Le programme "S'aimer soi-même" te sera super utile si :<br />
        </p>
        <ul>
          <li>Tu ne sais plus comment passer à la prochaine étape pour t'accepter et/ou te mener vers une perception de toi qui t'estime et lâche prise sur le passé.</li>
          <li>Tu souhaites ne plus subir le jugement intérieur.</li>
          <li>Tu as l'impression de ne pas aimer certaines parts de toi-même, ce qui t'empêche d'être vrai avec les autres et, parfois, d'aimer les autres.</li>
          <li>Tu souhaites mieux te comprendre et ne plus avoir peur de ce que tu peux ressentir (émotions, anxiété, mauvaise estime).</li>
        </ul>

        <p></p>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
          <Link to='/cours' className='link_hover' style={{ textAlign: 'center', color: '#FFD700', fontWeight: 'bold' }}>
          ✦ Voir les programmes ✦
          </Link>
        </div>

        <p><br />
          Et le cours "Écouter la vie en soi" te donnera des clés géniales si :<br />
        </p>
        <ul>
          <li>Tu souhaites pouvoir te valider toi-même.</li>
          <li>Tu ne te sens pas assez connecté à ton intuition et à tes ressentis.</li>
          <li>Tu te sens trop anxieux.se, ce qui t'empêche de vivre pleinement ta vie.</li>
          <li>Tu souhaites savoir mieux t'écouter.</li>
        </ul>
        <p>
          <br />
        </p>
        
        <p><br />
          Sache que maintenant, un <u>cours gratuit</u> sur la croissance personnelle est désormais disponible. La façon dont on évolue est un point intéressant à soulever. Dans cette réflexion, nous aborderons divers aspects qui peuvent poser problème, comme la façon dont on aborde l'échec, en passant par le besoin de changer, mais aussi notre état d'esprit concernant la positivité et le potentiel déni qu'on peut avoir face à nos défis ou blocages.
          <br /><br />
        </p>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
          <Link to='/cours' className='link_hover' style={{ textAlign: 'center', color: '#FFD700', fontWeight: 'bold' }}>
          ◈ Voir les programmes ◈
          </Link>
        </div>

        <GiphyEmbed 
          src="https://giphy.com/embed/xUOxfoGvvdloazNNdK"
          title="Cours Flowgraines"
          width="100%"
          height="100%"
        />
      </>
  );
}

export default HomePage;
