import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import TriangleArrowWithIndent from './triangleArrowWithIndent.js';
import { gsap } from "gsap";
import throttle from "lodash/throttle.js";

const onScroll = (set) => (e) => {
  const scrollY = window.scrollY;
  const opacity = Math.max(1 - scrollY / 800, 0);
  set({ scrollY, opacity });
};

function Parallax() {
  const [{ scrollY, opacity }, set] = useSpring(() => ({
    scrollY: 0,
    opacity: 1,
    config: { tension: 120, friction: 40 },
  }));

  useEffect(() => {
    const handleScroll = () => {
      // Synchroniser avec la position de défilement native
      set.start({ scrollY: window.scrollY });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [set]);

  const backgroundRef1 = useRef(null);
  const backgroundRef2 = useRef(null);

  const [textStyle, setTextStyle] = useSpring(() => ({
    from: { opacity: 0 },
    opacity: 1,
    config: { duration: 1000 }
  }));

  const [isDarkMode, setIsDarkMode] = useState(false);

  /* effet de particules */
  useEffect(() => {
    // Vérifie si l'utilisateur préfère réduire les animations
    const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;
    if (prefersReducedMotion) {
      return;
    }

    const particles = document.querySelectorAll(".particle");
    const colors = ['color1', 'color2', 'color3'];

    particles.forEach((particle) => {
      const randomColorClass = colors[Math.floor(Math.random() * colors.length)];
      particle.classList.add(randomColorClass);

      const initialX = Math.random() * window.innerWidth;
      const initialY = Math.random() * window.innerHeight;
      const initialScale = Math.random() * 0.9 + 0.7;

      // Position initiale aléatoire
      gsap.set(particle, {
        x: initialX,
        y: initialY,
        scale: initialScale,
      });
    });
  
    const animateParticle = (particle) => {
      const randomPosition = () => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
      });
  
      const newPos = randomPosition();
    
      const movementDuration = Math.random() * 5 + 10; // Durée du mouvement
      const fadeDuration = Math.random() * 1.5 + 2;  // Durée de la transition d'opacité
      const delay = Math.random() * 2;              // Décalage initial
      const newScale = Math.random() + 0.3;
    
      // Animation principale
      const timeline = gsap.timeline({
        onComplete: () => animateParticle(particle), // Redémarre le cycle
      });
    
      timeline
        .to(particle, {
          x: newPos.x,
          y: newPos.y,
          scale: newScale,
          opacity: Math.random() * 0.5 + 0.5, // Reste partiellement visible
          duration: movementDuration,
          delay: delay,
          ease: "power1.inOut",
        })
        .to(particle, {
          opacity: 0, // Disparition douce
          duration: fadeDuration,
          ease: "power2.out",
          onComplete: () => {
            const randomPos = randomPosition();
            const resetScale = Math.random() + 0.3;
            gsap.set(particle, { x: randomPos.x, y: randomPos.y, opacity: 1, scale: resetScale });
          },
        });
    };
  
    particles.forEach((particle) => animateParticle(particle));
  
    const handleMouseMove = throttle((event) => {
      const { clientX: mouseX, clientY: mouseY } = event;
  
      particles.forEach((particle) => {
        const rect = particle.getBoundingClientRect();
        const particleX = rect.left + rect.width / 2;
        const particleY = rect.top + rect.height / 2;
  
        const distance = Math.sqrt(
          Math.pow(mouseX - particleX, 2) + Math.pow(mouseY - particleY, 2)
        );
  
        if (distance < 120) {
          const angle = Math.atan2(particleY - mouseY, particleX - mouseX);
          const offsetX = Math.cos(angle) * (120 - distance);
          const offsetY = Math.sin(angle) * (120 - distance);
  
          // Stop l'animation en cours pour éviter les conflits
          gsap.killTweensOf(particle);
  
          // Animation de repoussement
          gsap.to(particle, {
            x: `+=${offsetX}`,
            y: `+=${offsetY}`,
            scale: 1.2,
            duration: 0.4,
            ease: "power2.out",
            onComplete: () => {
              animateParticle(particle);
            },
          });

          gsap.to(particle, {
            x: `+=${Math.random() * 50 - 25}`, // Mouvement subtil supplémentaire
            y: `+=${Math.random() * 50 - 25}`,
            delay: 0.1,
            duration: 1.7, // Retour plus long pour un effet fluide
            ease: "power1.inOut",
          });
  
          // Retour progressif à la taille normale
          gsap.to(particle, {
            scale: Math.random() + 0.3,
            duration: 1.2,
            ease: "power1.out",
          });
        }
      });
    }, 50);    

    window.addEventListener("mousemove", handleMouseMove);
  
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      gsap.killTweensOf(particles);
    };
  }, []);

  /* effet texte */
  useEffect(() => {
    const handleScroll = onScroll(set);
    window.addEventListener('scroll', handleScroll);

    const timeoutId = setTimeout(() => {
      setTextStyle({
        opacity: 0,
        config: { duration: 1800 }, 
      });
    }, 6000);

    setIsDarkMode(document.body.classList.contains('dark-mode'));

    // Lazy loading avec Intersection Observer
    const observerOptions = {
      root: null,
      threshold: 0,
      rootMargin: '200px',
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          const imageName = lazyImage.dataset.bg;
          const darkModeSuffix = isDarkMode ? 'nuit' : '';
    
          // Chargement de l'image haute résolution
          const highResImage = imageName.replace(/\.png$/, `${darkModeSuffix}.png`);
          const img = new Image();
          img.src = highResImage;
    
          // Une fois l'image complètement chargée
          img.onload = () => {
            lazyImage.style.backgroundImage = `url('${highResImage}')`;
            lazyImage.classList.add('loaded'); // Ajoute la classe `loaded`
            observer.unobserve(lazyImage); // Arrête l'observation de cette image
          };
    
          // Gestion des erreurs pour éviter un chargement infini en cas de problème
          img.onerror = () => {
            console.error(`Erreur lors du chargement de l'image : ${highResImage}`);
            observer.unobserve(lazyImage); // On désactive l'observation
          };
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    if (backgroundRef1.current) {
      observer.observe(backgroundRef1.current);
    }
    if (backgroundRef2.current) {
      observer.observe(backgroundRef2.current);
    }

    // MutationObserver pour observer les changements de classe sur le body
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          const isDark = document.body.classList.contains('dark-mode');
          setIsDarkMode(isDark);
          // Mettez à jour l'image de fond lorsque le mode change
          if (backgroundRef1.current) {
            const lazyImage = backgroundRef1.current;
            const imageName = lazyImage.dataset.bg;
            const darkModeSuffix = isDark ? 'nuit' : '';
            lazyImage.style.backgroundImage = `url('${imageName.replace(/\.png$/, `${darkModeSuffix}.png`)}')`;
          }
          if (backgroundRef2.current) {
            const lazyImage = backgroundRef2.current;
            const imageName = lazyImage.dataset.bg;
            const darkModeSuffix = isDark ? 'nuit' : '';
            lazyImage.style.backgroundImage = `url('${imageName.replace(/\.png$/, `${darkModeSuffix}.png`)}')`;
          }
        }
      });
    });
    mutationObserver.observe(document.body, { attributes: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
      clearTimeout(timeoutId);
    };
  }, [set, setTextStyle, isDarkMode]);

  return (
    <div className="backgrounds">
      <animated.div
        ref={backgroundRef1}
        className="background-layer1"
        data-bg="/pictures/backgroundLayerBack.png"
        style={{
          transform: scrollY.to((y) => `translateY(-${y/3}px)`),
        }}
      />
      <animated.div
        ref={backgroundRef2}
        className="background-layer2"
        data-bg="/pictures/backgroundLayerFront.png" 
        style={{
          transform: scrollY.to((y) => `translateY(-${y/5}px)`),
          opacity: opacity.to((o) => o),
        }}
      />
      <animated.h1 style={textStyle} className="beginH1 masked-text">
       <img src="/pictures/logo.png" alt="Logo Flowgraines" className="logo-style" />
       Flowgraines
      </animated.h1>
      <animated.h2 style={textStyle} className="beginH2">
        Cours en ligne pour s'aimer et s'épanouir !
      </animated.h2>
      <TriangleArrowWithIndent />

      {/* effet particules */}
      <animated.div className="particles-container">
        {Array.from({ length: 50 }).map((_, i) => (
          <div key={i} className="particle"></div>
        ))}
      </animated.div>
    </div>
  );
}

export default Parallax;

