import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import AudioPlayer from '../animations/audioPlayer.js';
import AnimatedH2 from '../animations/animatedH2.js';

function Chapitre8Secouter() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();
  const [animationStyle5, ref5] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 8 : Lâcher prise et le pouvoir du moment présent</h1>

        <h2 className="subsection-title">Lâcher prise</h2>

        <p>
          Lâcher prise, c'est comprendre que nous ne sommes pas nos pensées, et que celles-ci définissent la réalité uniquement si on se soumet à elles. 
          Les pensées peuvent être influencées par certains facteurs — des émotions, des croyances, et donc des expériences passées — 
          et si on s'y accroche trop, nous risquons de nous enfermer dans un personnage qui est à mille lieux de qui nous sommes vraiment.
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            En lâchant prise, on se donne de l'espace pour le <span className="gold">"fil d'or"</span> dont je vous ai parlé plus tôt. 
            Ce fil - cette intuition - ne peut être suivi que lorsqu'on se fait confiance, et qu'on arrête de tout analyser ou de tout juger.
          </p>
        </animated.div>

        <p>
          C'est un acte de foi, une manière d'accepter que la vie est en mouvement constamment et que nos pensées ne sont qu'un reflet momentané de ce mouvement. 
          En laissant les pensées passer, comme des nuages dans le ciel, on peut trouver une clarté intérieure.
        </p>

        <AnimatedH2 className="subsection-title">Le pouvoir du moment présent</AnimatedH2>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            Un autre aspect essentiel pour réguler ses pensées et réguler le mental est de se reconnecter au moment présent. 
            Le mental adore vagabonder entre le passé et le futur, en ressassant des événements ou en anticipant des situations 
            qui, souvent, n'arriveront jamais. Mais ces projections sont une source d'inquiétudes et de stress.
          </p>
        </animated.div>

        <p>
          En remettant sa conscience au présent, on coupe court à cette spirale. 
          Le moment présent est le seul instant où nous avons réellement un <span className="bold">pouvoir d'action</span>. 
          C'est là que nous pouvons ressentir pleinement, agir en conscience, et être en accord avec nous-même.
        </p>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
           En soi, nous sommes toujours dans le moment présent ; la seule chose qui change, c'est notre présence ou non : présents dans notre corps physique, dans notre corps émotionnel, et dans notre esprit.
          </p>
        </animated.div>

        <p className="bold">
          <em>Audio 4 : Astuces qui te permettront de ne plus perdre de temps</em>
        </p>

        {/* Lecteur audio intégré */}
        <AudioPlayer Url="audios/audio4.mp3" />

        <AnimatedH2 className="subsection-title">Se reconnecter à soi</AnimatedH2>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
            Enfin, réguler ses pensées et retrouver ce calme intérieur ne peut se faire sans une véritable reconnexion à soi. 
            Il s'agit de prendre du temps pour explorer son monde intérieur, ses désirs profonds, et ses aspirations véritables. 
            En s'accordant ces moments de présence à soi, on accède à sa propre vérité, loin des injonctions extérieures et des attentes de la société.
          </p>
        </animated.div>

        <p>
          Il y a des méthodes qui permettent de structurer ces phases de reconnexion. Je pense à l'Ikigai, ou tout simplement la création sous toutes ses formes et le fait d'essayer de nouvelles choses.
          Suivre le fil d'or, c'est justement cela : revenir à ce qui résonne profondément en nous, au-delà des bruits parasites du mental et des influences extérieures. 
          C'est apprendre à faire confiance à cette petite voix intérieure, celle qui sait déjà, au fond, ce qui est bon pour nous.
        </p>

        <animated.div className="animated-line" style={animationStyle5} ref={ref5}>
          <p className="animatable">
            Le centre, c'est ce qui nous unifie, là où l'on peut accueillir nos différentes facettes sans jugement. 
            C'est là où réside notre essence, là où l'on trouve la paix même dans les tempêtes extérieures. 
            À cet endroit, nous sommes complets, entiers, et en paix avec qui nous sommes.
          </p>
        </animated.div>

        <p>
          Écouter son corps, accepter le silence intérieur, s'autoriser la bienveillance font partie de cette connexion <em>et de l'écoute de notre </em> centre.<br /><br /><br />
        </p>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre8Secouter;
