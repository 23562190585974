import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext.js'; 

function withAuth(WrappedComponent) {
  return function(props) {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
      if (!currentUser) {
        navigate('/');
      } else {
        setIsAuthorized(true); // Autorise le rendu du composant
      }
    }, [currentUser, navigate]);

    // Attendez de vérifier l'authentification avant de rendre
    return isAuthorized ? <WrappedComponent {...props} /> : null;
  };
}

export default withAuth;