import React, { useState, useEffect } from 'react';
import { useNavigate , useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from './AuthContext.js';

// Initialisez Stripe avec votre clé publique 
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Verification = () => {
  const { currentUser, loadUserData } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState('En cours de vérification...');
  const [error, setError] = useState('');
  const [paiementValide, setPaiementValide] = useState(false);

  useEffect(() => {
    const verifyPayment = async () => {
      if (!currentUser) {
        setPaymentStatus("Vous devez être connecté pour obtenir un cours.");
        return;
      }
      const queryParams = new URLSearchParams(location.search);
      const clientSecret = queryParams.get('payment_intent_client_secret');
  
      if (!clientSecret) {
        setError('Informations de paiement manquantes.');
        setPaymentStatus('Paiement échoué. Veuillez réessayer.');
        return;
      }
  
      try {
        const stripe = await stripePromise;
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
  
        if (paymentIntent.status === 'succeeded') {
          setPaymentStatus('Paiement réussi ! Merci pour votre achat.');
          setPaiementValide(true);
        } else {
          setPaymentStatus('Paiement échoué. Veuillez réessayer.');
          setError(paymentIntent.last_payment_error?.message || 'Une erreur est survenue lors du processus de paiement.');
        }
      } catch (error) {
        console.error('Erreur inattendue lors de la vérification du paiement :', error);
        setError(error.message || 'Erreur inattendue.');
        setPaymentStatus('Paiement échoué. Veuillez réessayer.');
      }
    };
    verifyPayment();

  }, [location.search, currentUser]); 

  const goToCourses = async () => {
    if (currentUser) {
      try {
        await loadUserData(currentUser.uid);
        navigate('/cours');
      } catch (error) {
        console.error('Erreur lors du rechargement des données utilisateur :', error);
      }
    } else {
      console.error('Utilisateur non authentifié.');
    }
  };

  return (
    <div className="verification-container">
      <h2>Statut du Paiement</h2>
      <p>{paymentStatus}</p>
      {error && <p>Erreur : {error}</p>}
      {paiementValide && (
        <p>
          Vous aurez accès aux cours par ici :{' '}
          <span 
            onClick={goToCourses} 
            style={{ textDecoration: 'none', color: '#d6c46b', cursor: 'pointer' }}
          >
            Liste des cours
          </span>
        </p>
      )}
    </div>
  );
};

export default Verification;
