import React from 'react';
import { Link } from 'react-router-dom';
import YouTubeVideo from "../animations/videoPlayed.js"

function Chapitre4Croissance() {

  return (
      <>
        <h1 className='chapitre'>Chapitre 4 : Accepter l'échec</h1>

        <p>
        Échouer est quelque chose de normal. C'est même encourageant car cela signifie qu'on essaie et qu'on se donne l'<b>opportunité d'apprendre</b>.<br />
        S'il n'y avait pas de débutant sur Terre, alors il n'existerait absolument aucun expert en quoi que ce soit. <span className='gold'>Débuter</span> est le premier pas qui permet à tout un chacun de démarrer ce cheminement qu'est la croissance personnelle. Apprendre, essayer, échouer, refaire, tomber, se relever, comprendre, continuer. <br /><br />

        Plus on est ok avec le fait d'échouer ou bien de perdre, plus on peut développer une <b>résilience</b> à cela.<br /><br />
        </p>

        <p><em>La vidéo n'est pas de moi, mais je sais ô combien ses mots sont justes.</em></p>

        
        <YouTubeVideo videoId="uO_1pidqv9M" />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <p>
            <span className='bold'>"Like everyone else</span> <br />
            <em>Comme tout le monde</em>
          </p>
          <p>
            <span className='bold'>you want to learn the way to win</span> <br />
            <em>tu veux apprendre comment gagner</em>
          </p>
          <p>
            <span className='bold'>but never to accept the way to lose</span> <br />
            <em>mais sans jamais accepter la manière de perdre</em>
          </p>
          <p>
            <span className='bold'>To accept defeat</span> <br />
            <em>Accepter la défaite</em>
          </p>
          <p>
            <span className='bold'>to learn to die is to be liberated from it</span> <br />
            <em>apprendre à mourir, c'est s'en libérer</em>
          </p>
          <p>
            <span className='bold'>So when tomorrow comes you must free your ambitious mind and learn the art of dying."</span> <br />
            <em>Alors quand demain viendra, tu dois libérer ton esprit ambitieux et apprendre l'art de mourir.</em>
            <br />- Bruce Lee
          </p>
      </div>

        <p><br />
        Jamais la crainte d'échouer ne nous permettra de vivre mieux. Au contraire, cela nous rend inaptes à se donner les moyens de réussir et cela nous bloque même dans le fait de se choisir. <br /><br />
        <span className='gold'>Se choisir</span> c'est se donner le droit d'être soi-même et de faire les choix qui nous sied. Plus on craint l'échec, plus nous avons tendance à nous éloigner de ses choix authentiques, car ceux-ci nous poussent souvent à nous <b>débrider et à réaliser les choses d'une manière neuve et originale</b>.<br /><br />
        L'échec, en réalité, est un <span className='underline'>précieux enseignant</span>. Chaque erreur commise est une leçon, une opportunité de se perfectionner et de s'approcher un peu plus de nos objectifs. <br /><br />Quand on embrasse l'échec comme une étape naturelle du processus, on libère notre esprit de la prison de la peur et on se permet de rêver plus grand et d'oser plus fort. <br /><br />

        Pour sa propre estime de soi, faire des choses qui nous font kiffer est important. Fais des choses que tu aimes. Si tu trouves que ce que tu fais est nul, ne le fais pas, à moins que tu aies la sensation <u>d'apprendre</u> - ce qui est indicateur important, mais, à ce moment-là, tu es enthousiaste à l'idée d'atteindre un objectif.
        Le moindre pas que tu puisses réaliser, aussi petit qu'il puisse être, c'est déjà énorme et les bénéfices liés à cette action peuvent se révéler énormes aussi. 
        <br /><br />

        Ce sont souvent les échecs qui nous obligent à sortir de notre zone de confort et à découvrir des talents et des capacités qu'on ignorait avoir. En fin de compte, c'est cette persévérance et cette capacité à se relever après chaque chute qui définissent véritablement le succès.<br /><br /><br />
        </p>

        <div className="button">
          <Link to="/formationCroissance" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>

  );
}

export default Chapitre4Croissance;
