import React, { useState, useRef, useEffect } from 'react';
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";

const AudioPlayer = ({ Url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.7);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0); // Durée totale de l'audio
  const [progressValue, setProgressValue] = useState(0); // Pourcentage de progression
  const [streamUrl, setStreamUrl] = useState(""); 
  const audioRef = useRef(null);

  // Charger l'URL audio depuis Firebase Storage
  useEffect(() => {
    const storage = getStorage();
    const audioFileRef = storageRef(storage, Url);

    getDownloadURL(audioFileRef)
      .then((url) => {
        setStreamUrl(url);
      })
      .catch((error) => {
        console.error("Erreur lors de l'obtention de l'URL :", error);
      });
  }, [Url]);

  // Charger l'audio lorsque l'URL est prête
  useEffect(() => {
    if (audioRef.current && streamUrl) {
      audioRef.current.load();
      setIsPlaying(false); // Réinitialise l'état de lecture
      setCurrentTime(0);   // Réinitialise le temps actuel
      setProgressValue(0); // Réinitialise la progression
    }
  }, [streamUrl]);

  // Gestion des événements audio
  useEffect(() => {
    const audioElement = audioRef.current;

    if (!audioElement) return;

    const updateTime = () => {
      const current = audioElement.currentTime || 0;
      const totalDuration = audioElement.duration || 0;

      setCurrentTime(current);
      setProgressValue(totalDuration > 0 ? (current / totalDuration) * 100 : 0);
    };

    const handleLoadedMetadata = () => {
      const totalDuration = audioElement.duration || 0;
      setDuration(totalDuration); // Met à jour la durée totale
    };

    audioElement.addEventListener("timeupdate", updateTime);
    audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      audioElement.removeEventListener("timeupdate", updateTime);
      audioElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [streamUrl]);

  // Lecture/Pause de l'audio
  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Gestion du volume
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  // Gestion de la barre de progression
  const handleProgressChange = (e) => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const newTime = (e.target.value / 100) * duration; // Utilise la durée totale pour calculer le temps
      audioElement.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  // Formatte le temps en minutes:secondes
  const formatTime = (time) => {
    if (isNaN(time) || time === 0) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  return (
    <div
    style={{
      width: "80%",
      backgroundColor: "#FFA500",
      padding: "10px",
      borderRadius: "8px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "auto",
    }}
  >
    {streamUrl && <audio ref={audioRef} src={streamUrl} preload="metadata" />}
    {/* Bouton Lecture/Pause */}
    <button
      onClick={togglePlayPause}
      aria-label={isPlaying ? "Pause" : "Lecture"}
      style={{
        background: "none",
        border: "none",
        cursor: streamUrl ? "pointer" : "not-allowed", // Désactive le clic si pas encore prêt
        opacity: streamUrl ? 1 : 0.5, // Grise le bouton si le flux n'est pas chargé
      }}
      disabled={!streamUrl} // Empêche l'interaction si l'URL n'est pas chargée
    >
      <img
        src={isPlaying ? "/pictures/pause.png" : "/pictures/play.png"}
        alt={isPlaying ? "Pause" : "Lecture"}
        style={{ width: "8vw", height: "auto", maxWidth: "50px" }}
      />
    </button>

    {/* Barre de progression */}
    <div style={{ marginTop: "10px" }}>
      <span>
        {formatTime(currentTime)} / {formatTime(duration)}
      </span>
    </div>
    <input
      type="range"
      min="0"
      max="100"
      value={progressValue}
      onChange={handleProgressChange}
      style={{ width: "100%", marginTop: "5px" }}
      aria-label="Progression de l'audio"
      disabled={!streamUrl} // Désactive si l'URL n'est pas prête
    />

    {/* Slider de volume */}
    <label style={{ marginTop: "20px", display: "block" }}>
      <img
        src="/pictures/volume.png"
        alt="Volume"
        style={{ width: "24px", height: "auto" }}
      />
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={handleVolumeChange}
        style={{ width: "80%", marginTop: "5px", marginLeft: "1%" }}
        aria-label="Volume de l'audio"
        disabled={!streamUrl} // Désactive si l'URL n'est pas prête
      />
    </label>

    {/* Texte de chargement */}
    {!streamUrl && (
      <p
        style={{
          fontSize: "16px",
          color: "#ffffff",
          textAlign: "center",
          marginTop: "20px", // Positionner sous les autres éléments
        }}
      >
        Chargement de l'audio...
      </p>
    )}
  </div>
  );
};

export default AudioPlayer;
