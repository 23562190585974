import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';

function Chapitre5Secouter() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 5 : Se valider soi-même pour devenir soi</h1>

        <p>
          Savoir se valider soi-même, c'est apprendre à s'estimer et savoir se percevoir légitime quand : tout le monde apprend, 
          et que tu as toi-même tes propres perceptions, ta propre vérité.
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            Cela ne signifie pas que tu ne peux pas apprendre des autres parce que se valider ça voudrait dire “être hermétique au regard, 
            à l'opinion, et à la validation des autres”.
          </p>
        </animated.div>

        <p>
          Justement, tu sais te valider, car tu sais que tu as envie d'apprendre de telle façon et auprès de telle personne. 
          Et cela n'a rien à voir avec l'hermétisme. On peut apprendre de n'importe qui. 
          On a bien cette <span className="bold">intuition</span> qui nous indique que l'avis de telle personne résonne avec nous ou que telle façon de faire semble bonne pour nous.
        </p>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            <u>La validation personnelle naît d'une profonde écoute de soi, de cette capacité à se reconnaître pleinement sans chercher l'approbation extérieure, <b>tout en restant ouvert aux autres autant qu'on est ouvert à de nouvelles idées venant de soi.</b></u>
          </p>
        </animated.div>

        <p>
          En cultivant cette force, tu renforces ta <span className="bold">confiance intérieure</span>. 
          Tu n'es plus tributaire du regard des autres pour te sentir légitime, ce qui te permet de t'exprimer avec plus de transparence. 
          C'est cette confiance qui te permet de t'affirmer, de dire non quand quelque chose ne résonne pas avec toi, 
          ou de t'ouvrir à de nouvelles idées sans te sentir menacé par la différence.
        </p>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
            Cette autonomie émotionnelle est essentielle pour maintenir des relations saines. 
            Lorsque tu sais te valider toi-même, tu entres en relation avec les autres en tant qu'égal.
          </p>
        </animated.div>

        <p>
          Il y a une forme de sécurité intérieure dans l'auto-validation. 
          Tu développes cette force qui te permet de faire face aux critiques ou aux jugements extérieurs sans que cela n'ébranle ton estime de toi. 
          Tu apprends à accueillir des retours extérieurs de manière constructive, sans que ta valeur ne soit remise en question.
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
            Tu réalises ainsi peu à peu que ta véritable force ne réside pas dans une version idéalisée de toi, mais dans celle qui est déjà présente, ici et maintenant. 
            Elle vient de ta capacité à être en harmonie avec ton naturel, à te permettre de ressentir pleinement ce que la vie te propose, sans jugement.
          </p>
        </animated.div>

        <p>
          Ce qui est naturel en toi est juste. Ta <span className="gold">spontanéité</span>, tes ressentis, tes émotions brutes : 
          tout cela est une expression authentique de ce que tu es à cet instant. C'est dans cette acceptation de soi, dans cette permission que tu te donnes d'être tel(le) que tu es, que tu puises la plus grande liberté.
        </p>

        <p>
            En acceptant cela, tu te donnes la liberté d'évoluer sans chercher à plaire et à te conformer. 
            C'est un chemin de liberté intérieure, où la justesse ne vient plus de l'extérieur, mais d'un alignement avec ton essence.
            Cette justesse, c'est de reconnaître que ce qui émerge de toi naturellement est déjà suffisant. 
            Tu n'as pas besoin d'être autre chose que toi-même pour être légitime et puissant.<br /><br /><br /><br /><br /><br />
          </p>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre5Secouter;
