import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import AudioPlayer from '../animations/audioPlayer.js';
import AnimatedH2 from '../animations/animatedH2.js';

function Chapitre4Secouter() {
  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 4 : Just fucking go with the flow</h1>

        <p>
          <em>Aide toi et le ciel t'aidera.</em> N'oubliez pas que dans votre quotidien, dans vos entreprises, 
          peut-être que la vie est en train d'essayer de vous aider, et qu'elle n'a pas besoin que vous réussissiez, 
          mais juste que vous <span className="bold">essayiez</span>. En fait, c'est le cas: la vie nous aide toujours mais 
          d'une telle façon que nous ne comprenons pas forcément pourquoi et comment elle manœuvre.
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            Cet essai, ce pas qu'on fait en avant peut être ressenti comme un pas dans le vide. Il nous amène vers un autre pas, 
            peut-être vers des informations cruciales, et vers un guide pour la suite. 
            L'important n'est pas d'avoir tout prévu ou tout compris, mais d'être en mouvement et de rester ouvert à ce qui pourrait se présenter sur notre route.
          </p>
        </animated.div>

        <p>
          Chaque essai est une invitation à découvrir quelque chose de nouveau : sur toi, sur le monde, ou sur ce que tu veux vraiment. 
          Parfois, il suffit d'avancer d'un pas pour que le prochain devienne plus clair. Ce n'est pas seulement un acte de courage, 
          c'est aussi une manière de dialoguer avec la vie. En essayant, tu donnes <span className="bold underline">l'opportunité aux choses de se manifester</span>, 
          <span className="bold underline">et aux solutions de se révéler</span>. 
          Et souvent, ces réponses viennent de manière inattendue, à travers des rencontres, des évènements, etc, que tu n'aurais pas pu anticiper.
        </p>

        <AnimatedH2 className="subsection-title">Se mettre au niveau de ses objectifs</AnimatedH2>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            Placer un de tes objectifs sur un piédestal, c'est le rendre supérieur à toi, et à ton niveau. 
            Le mindset que tu construis avec ça est que tu penses que tu aurais énormément à perdre si tu n'arrivais pas à atteindre cet objectif. 
            De ce fait, une fois dans l'action, ce qu'il se passera est que tu te sentiras trop petit, freiné par cette éminence qu'est ton objectif.
          </p>
        </animated.div>

        <p>
          Tu sentiras que tu es en train de jouer ta vie dans cette ambition, et que tu as trop à perdre si ça ne marchait pas. 
          Ainsi, inconsciemment, tu vas forcément finir par t'auto-saboter, évitant de passer à l'action où te détournant de la vraie direction que tu souhaitais prendre.
        </p>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
            Pour te permettre de réussir, mets toi au niveau de tes objectifs. Ils n'ont pas plus de valeur que toi. 
            De même, quand tu commences quelque chose de nouveau, tu apprends et c'est forcément nécessaire pour la suite, pour grandir et progresser. 
            Si tu t'autorises à échouer (parce que tu te sens capable dans ce que tu entreprends, et que tu te sens en phase avec tes objectifs), 
            alors tu te donnes d'autant plus le droit d'évoluer. Plus tu te donnes ce sentiment de sécurité, <u>plus tu réussiras dans tes projets</u>.
          </p>
        </animated.div>

        <p>
          Que ça soit dans nos projets, le professionnel, ou des évènements de notre quotidien, l'écoute est une façon d'être qui est soit quelque chose de naturel, 
          soit quelque chose pour lequel on a, avec le temps, mis des murs et des barrières notamment pour s'adapter en mode survie. 
          Et ainsi, la vie peut nous paraître semée d'embûches, et ceci, même si tu es une personne intuitive.
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
            En fait les personnes les plus sereines sont plus généralement les gens qui sont ancrés. Confiant et habitué à une vie remplie de défis, 
            avec la sensation que “ça va le faire; on va trouver un moyen; etc”. Pour autant, il arrive que ces mêmes personnes se retrouvent au pied du mur avec plus aucune autre solution que de suivre leur intuition (le cœur souvent).
          </p>
        </animated.div>

        <p>
          Quelqu'un de plus intuitif peut aussi s'adapter par insécurité et doit, par la suite, relever des défis à propos de sa confiance 
          et son niveau d'incarnation de qui il ou elle est. Il nous est toujours rappelé notre pourquoi au travers de ressentis, de prises de conscience, etc.<br /><br /><br />
        </p>

        <p className="bold">
          <em>Audio 2 : Construire avec confiance</em>
        </p>

        {/* Lecteur audio intégré */}
        <AudioPlayer Url="audios/audio2.mp3" />

        <p><br /><br /></p>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre4Secouter;
