import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import AudioPlayer from '../animations/audioPlayer.js';
import { useAnimatedTrail } from '../animations/animatedTrail.js'

function Chapitre3Secouter() {
  const { animatedItems, ref } = useAnimatedTrail([
    <span>1- À ta créativité de s'exprimer librement</span>,
    <span>2- De t'accepter et d'être plus serein</span>,
    <span>3- D'être plus mature émotionnellement</span>,
  ]);

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 3 : Vulnérabilité et écoute des émotions, mille pas vers la liberté</h1>

        <p>
          <em>La vulnérabilité c'est le fait que tu te reconnectes à qui tu es à travers un aspect émotionnel et que tu reconnaisses que tu es vulnérable et puissant tout autant que les autres.</em>
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            Derrière ce mot se cache de nombreuses choses et notamment des choses que l'on n'aime pas en général. 
            Pourtant, c'est la <span className="gold">clé de l'authenticité</span>, de la connexion à soi, de la créativité aussi, et de bien d'autres ressources.
          </p>
        </animated.div>

        <p>
          Le perfectionnisme et la résistance aux émotions peuvent en effet être des obstacles à la <span className="bold">créativité</span> et à l'expression de soi.
        </p>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            En essayant d'être parfait et en résistant à nos émotions, nous pouvons nous couper de notre authenticité et de notre vulnérabilité. 
            Or, c'est justement dans cette dernière que réside notre <span className="gold">force</span> et notre capacité à connecter avec les autres.
          </p>
        </animated.div>

        <p>
          Jamais personne ne se sentira vraiment connecté et bien avec quelqu'un qui ne fait que jouer un rôle déconnecté de qui il est et essayant d'être adapté, aimé ou autre (en tout cas sur le moyen-long terme).
        </p>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
            La vulnérabilité, c'est accepter de se montrer tel qu'on est, avec nos forces et nos faiblesses. 
            C'est reconnaître que nous sommes tous humains et que nous avons tous des émotions et des expériences qui nous rendent uniques. 
            En étant vulnérable, nous ouvrons la porte à la connexion et à l'empathie.
          </p>
        </animated.div>

        <p className="animatable">
          En l'acceptant et en te reconnectant à tes émotions, tu permets : 
        </p>

        <div ref={ref} className="container-trail"> 
          {animatedItems}
        </div>

        <p><br /><br /><br /><br />
          Chaque pas sur ce chemin est courageux. C'est le choix de regarder en face ce qu'on ressent, de l'explorer, parfois de le comprendre. 
          Et à travers ce processus, ce n'est pas seulement la connaissance de soi que nous approfondissons, mais notre capacité à vivre de manière plus libre, 
          déliée des chaînes de notre attachement à un ego qui provoque une tyrannie intérieure.<br /><br /><br />
        </p>

        <p className="bold">
          <em>Audio 1: S'ouvrir à soi en acceptant ses émotions</em>
        </p>

        {/* Lecteur audio intégré */}
        <AudioPlayer Url="audios/audio1.mp3" />

        <p><br /><br /></p>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre3Secouter;
