import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';

function Chapitre1Secouter() {
  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 1 : La résistance à nos émotions</h1>

        <br />
        <p className="bold gold citation">“Ce n'est pas l'émotion qui nous fait souffrir, mais la résistance que nous lui opposons.”</p>

        <p>
          Rencontrer des résistances face à ses émotions est un fait courant, plutôt quotidien pour la plupart. 
          Résister à une émotion, c'est s'y confronter, voire la fuir, plutôt que de savoir la ressentir et être comme un observateur de son 
          <span className="gold"> état intérieur</span>. Ce sont ces résistances ou ces évitements, plus que les émotions elles-mêmes, qui, en réalité, créent de la souffrance.
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
            Avec ces barrières, plus nous approchons de l'émotion, la touchant du bout des doigts, plus nous avons l'impression qu'une sensation désagréable s'intensifie. 
            Pourtant, c'est le signe qu'on s'approche de quelque chose à vraiment prendre en compte. Dès lors que nous nous ouvrons véritablement à l'émotion, elle cesse finalement d'être une source de complications ou de douleurs, et redevient simplement <span className="gold">l'information qu'elle est à la base</span>.
          </p>
        </animated.div>

        <p>
          En réalité, ce que nous <em>ressentons</em> lorsque nous <span className="gold">effleurons une émotion</span>, spécifiquement qui nous déclenche des réactions, ça n'est pas l'émotion en elle-même ! C'est <span className='bold'>notre peur de ce qu'elle représente</span>. Cette peur agit comme une loupe, amplifiant l'intensité perçue de celle-ci.
        </p>

        <p>
          Dans ces moments où elle commence à devenir difficile à ignorer, on ressent quelque chose qui nous donne peut-être envie de faire marche arrière. Et à cet instant, il est bon de ne pas céder et de s'accorder le droit de ressentir.
        </p>

        <p>
          On a l'impression que cette intensité émotionnelle est un signal qui nous dit de ne pas aller plus loin. Mais en réalité, c'est le contraire. Cette sensation est le signe de notre résistance et d'une information à regarder en face pour saisir une clarté, une honnêteté envers soi, ou encore une compréhension.
        </p>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
          Quand on ose enfin s'ouvrir pleinement à ce que l'on ressent, à ce moment là, quand on arrête de vouloir fuir, cela cesse d'être une source de gêne, de douleur ou de souffrance. Au pire, c'est quelque chose d'un peu "lourd" à traverser. Le ressenti n'est plus quelque chose à éviter, mais bel et bien une information sur ce que nous vivons à l'intérieur de nous.
          </p>
        </animated.div>

        <p>
          Nos émotions sont souvent perçues comme des obstacles, des choses à contourner (et donc à réprimer). Mais en réalité, elles sont des messagères. Elles nous indiquent ce qui compte, ce qui nécessite notre attention. Lorsqu'une émotion devient intense, c'est comme si elle frappait plus fortement à la porte de notre conscience, demandant à être reconnue.
        </p>

        <p>
          Mais trop souvent, nous avons peur de ce que nous pourrions découvrir en l'ouvrant. Alors, on résiste, on détourne le regard, on essaye de faire taire cette voix intérieure. Et c'est là que la souffrance débute. Ce n'est pas l'émotion en elle-même qui nous fait mal, mais notre refus de l'accepter.
        </p>

        <p>
          Approcher de ce ressenti, peut être perturbant, c'est un peu comme se tenir au bord d'un précipice. On croit que sauter, c'est plonger dans le vide, dans quelque chose de dangereux. Mais en réalité, une fois qu'on s'y laisse aller, on réalise que cette émotion n'est pas là pour nous détruire. Elle est là pour nous éclairer.
        </p>

        <p>
          Les accepter, c'est leur permettre de révéler leur message. On cesse de les percevoir comme des ennemies et on commence à les voir pour ce qu'elles sont : des guides, des informations précieuses qui nous aident à mieux comprendre ce que nous vivons et <u>comment nous pensons</u>.
        </p>

        <p>
          Prenez le temps, lors d'un moment difficile, pour être plus à l'écoute de votre corps et donc de votre ressenti à ce moment là. Vous découvrirez que ce que vous craigniez tant était simplement la vérité que cette émotion vous rendait visible.
        </p>

        <p>
          Ouvrir les bras au monde émotionnel demande souvent de déconstruire une part d'auto-jugement. On se donne la chance de vivre réellement, lumineusement, <span  className='underline'>transformant la peur en compréhension et la douleur en sagesse</span>. <br /><br /><br /><br />
        </p>

        <div className="button">
          <Link to="/formationSecouter" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre1Secouter;