import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Navbar from './Navbar.js';
import Accessibilite from './Accessibilite.js';
import Footer from './Footer.js';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 100); 

    return () => clearTimeout(timer);
  }, [location]);

  return null;
}

const DisplayWrapper = ({ children }) => {
  const routesWithParallax = ['/', '/formationSaimer', '/formationCroissance', '/formationSecouter'];
  const location = useLocation();
  const hasParallax = routesWithParallax.includes(location.pathname);
  const isHome = location.pathname === '/';
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 680);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 680);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="home">
      <ToastContainer />
      <Accessibilite />
      <ScrollToTop />
      <Navbar showParallax={hasParallax} isMobile={isMobile} />

      <div
        className={`content ${isHome ? 'home-background' : ''} ${isMobile && !hasParallax ? 'mobile-padding' : 'desktop-padding'}`}
      >
        {children}
      </div>

      <Footer />
    </div>
  );
};

export default DisplayWrapper;
