import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const GiphyEmbed = ({ src, title, width, height }) => {
  const iframeRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (iframeRef.current) {
        const rect = iframeRef.current.getBoundingClientRect();
        // Vérifie si l'élément est dans le viewport mais proche du haut (20%)
        if (rect.top <= window.innerHeight * 0.1) {
          setIsVisible(false); // Déclenche la disparition
        } else {
          setIsVisible(true); // Reste visible
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{ opacity: isVisible ? 1 : 0, transition: 'opacity 0.5s ease-out' }}>
      <iframe
        ref={iframeRef}
        src={src}
        title={title}
        width={width}
        height={height}
        frameBorder="0"
        className="giphy-embed"
        allowFullScreen
      ></iframe>
    </div>
  );
};

// PropTypes pour valider les valeurs transmises au composant
GiphyEmbed.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default GiphyEmbed;
