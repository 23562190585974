import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import GiphyEmbed from '../animations/giphyEmbed.js'


function Chapitre4Saimer() {
  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 4 : Émotion et empathie</h1>

        <p>
        Il n'existe pas de sensibilité qui soit de “trop”. Ce qui est <b>normal c'est justement d'être sensible</b>. Dans une société où on étiquette certaines personnes “d'hypersensibles”, il vaut mieux prendre conscience que certains ne le sont plutôt <b>pas assez</b>. <br />C'est comme les produits bio : c'est la normalité ! Ce qui n'est pas normal, c'est que ce qui soit étiqueté est le biologique, et non l'inverse. Si on vivait dans une société à l'endroit, les étiquettes seraient sur les produits non-bio. </p>
        <p>C'est une logique qui doit reprendre sa place à tous niveaux et bien sûr humainement parlant.
        L'empathie est une qualité importante. Elle n'est pas à confondre avec le fait de devenir trop perméable face à l'émotion d'autrui. C'est une capacité qui se doit d'être saine. 
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">On sait se mettre à la place des autres, mais il est nécessaire d'apprendre à rester soi : je vis et je transmets ce que j'incarne, et le fait de <span className="gold">rayonner</span>  me permet de ne pas subir la réalité d'une autre personne. 
          </p>
        </animated.div>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
          Je te vois, je te ressens, mais j'ai pleinement envie de vivre ce que <span className="gold">JE</span> suis.
          </p>
        </animated.div>

        <p>
        Ainsi, l'empathie nous permet à tous de nous lier aux autres et d'apporter, si on en sent le besoin, une aide.<br /><br />
        <GiphyEmbed 
          src="https://giphy.com/embed/l3mZjsC38twDeMLOE"
          title="Good Person!"
          width="480"
          height="240"
        />
        <br /><br /><br />
        <br /><br /> Nous pouvons parfois tout simplement apporter notre présence ce qui est déjà, la plupart du temps beaucoup, à la place de mentaliser la situation et de parler sans être en lien vraiment avec la personne.<br /><br />
        S'aimer, c'est aimer les autres, car toute haine est une différenciation et une distanciation d'avec l'autre, ainsi qu'une part de nous-même qu'on a du mal à aimer. 
        </p>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
          Souvent une insécurité est en lien avec une expérience vécue, et demeure présente à cause d'une prise de recul qui n'a pas encore été faite.
          Les émotions sont une part de l'être qui influent sur notre mental et impactent notre corps. 
          </p>
        </animated.div>

        <p>
        J'aime l'image des vagues et du surfeur. Les émotions sont effectivement bien ces vagues qui peuvent nous emmener loin si on les surfent correctement. Mais elles peuvent tout aussi bien nous faire couler.<br /><br />
        Nous n'avons pas à subir nos émotions. La réalité est que c'est fonction de notre conscience émotionnelle.
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
          Il y a des personnes qui, sensibles, perçoivent la moindre petite onde sur leur lac émotionnel.
          </p>
        </animated.div>

        <p>
        D'autres ont besoin d'une vague pour commencer à vraiment ressentir.
        Les émotions sont des messages de la part de notre Moi profond. <br /><br />
        Elles indiquent par <span className="gold">la colère</span> <b>si nos limites sont franchies et que cela nous touche négativement</b>. On pourrait dire “qu'il se passe quelque chose d'anormal pour soi ou pour une autre personne”; ça nous touche dans nos valeurs. Globalement, la colère est l'émotion qui informe à propos <span className="underline">du respect de notre espace vital</span> (corps, esprit, valeurs...). <br /><br /><span className="gold">La tristesse</span>, elle, nous indique qu'un plus ou moins grand changement se produit en nous. Elle fait souvent référence à une fin (deuil). Ça peut être la volonté profonde de <b>vivre différemment, une transformation intérieure, et/ou le début d'une prise de conscience</b>. Elle est aussi en lien avec l'acceptation.
        <br /><br />
        Bien sûr, la palette émotionnelle est riche de nuances plus ou moins complexes. 
        <span className="gold"> La joie</span>, elle, nous propulse dans des directions où nous souhaitons aller. C'est une émotion <b>expansive</b> contrairement à la tristesse. Elle est un peu comme une boussole qui nous indique notre Nord personnel.
        </p>
        <p><b>Pourquoi nous ne voulons que des joies et pas de tristesse ou de colère ? </b> 
        Pourtant, certaines joies ne sont pas si faciles à accepter. Nos croyances nous poussent parfois à être rabat-joie avec nous-même. Une croyance par exemple : qu'on ne mérite pas de réussir ou de recevoir. Ou bien la croyance que nous ne sommes pas légitimes. Tout de suite, la joie s'efface... 
        Mais quelles que soient les émotions qui nous traversent, elles sont toujours là pour une bonne raison. Et elles retoqueront à la porte, de plus en plus fort, <span className='underline'>jusqu'à ce qu'on leur ouvre.</span><br /><br />
        </p>      

        <p>
        <b>On peut voir les émotions comme des enfants :</b> tu ne peux pas les laisser contrôler ta vie, mais tu dois <span className="gold">les accompagner et les écouter.</span> <br /><br />
        Ainsi, on doit en prendre soin et être particulièrement attentionné avec. Et il est nécessaire de ne pas subir leurs états d'âme (les émotions), car un enfant a une non-maîtrise de celles-ci de par le fait qu'il est en plein développement. De même, le passage et la digestion de nos propres émotions doit nous affecter le moins possible négativement. Pour ainsi dire, on fait en sorte de ne pas les subir, comme un petit enfant qu'on doit écouter et accompagner.<br /><br />
        </p>

        <p><span className="bold">Guérir de la honte</span> autour des émotions peut être un premier très grand pas pour les aborder. À contrario, tu ne t'autoriseras jamais à vraiment <span className="gold">ressentir</span>. Dans ce cas de honte de certaines émotions, notre instinct est constamment biaisé, parce que tu ne vois pas tes émotions comme une part de ta nature humaine et comme une part de qui tu es.</p>

        <p>En ne surmontant pas cette honte, tu entretiens une <span className="underline">perception erronée</span> : le fait de voir l'émotion comme une complication, une problématique et même un défaut. Tu pourrais t'en vouloir d'être ainsi, humain.</p>

        <p>Lorsqu'on parvient à guérir de cette honte, on voit que les émotions n'ont pas à diriger notre vie; elles peuvent devenir des <span className="bold">alliées</span>, des <span className="bold">guides précieux</span> qui enrichissent notre expérience. C'est à ce moment-là que tu te rapproches de tes véritables objectifs, car tu n'es plus honteux de qui tu es.<br /><br /></p>

        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>

  );
}

export default Chapitre4Saimer;
