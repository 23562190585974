import React, { useState, useEffect, useRef } from 'react';
import { useTrail, animated } from '@react-spring/web';

export const useAnimatedTrail = (children, config = { mass: 5, tension: 1600, friction: 200 }) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const ref = useRef(null);

  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    ...config,
    opacity: triggerAnimation ? 1 : 0,
    x: triggerAnimation ? 0 : 20,
    height: triggerAnimation ? 110 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  useEffect(() => {
    const currentRef = ref.current; // Copier la valeur de ref.current dans une variable locale

    const observer = new IntersectionObserver(
      ([entry]) => setTriggerAnimation(entry.isIntersecting),
      {
        root: null,
        rootMargin: '-40% 0px -40% 0px', // Ajuste la marge pour déclencher plus tôt et garder active
        threshold: 0.1, // Déclenche lorsque 10% de l'élément est visible
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Utiliser currentRef pour le nettoyage
      }
    };
  }, []);

  const animatedItems = trail.map((style, index) => (
    <animated.div
      key={index}
      className="trailsText"
      style={{
        ...style,
        transform: style.x.to(x => `translate3d(${x}px,0,0)`),
        height: style.height.to(height => `${height}px`),
        opacity: style.opacity,
      }}
    >
      {items[index]}
    </animated.div>
  ));

  return { animatedItems, ref };
};
