import React, { useEffect, useState } from 'react';
import { db } from '../donnees/firebase.js';
import { collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../AuthContext.js';

const NewsletterPage = () => {
  const [loadingEmails, setLoadingEmails] = useState(true);
  const [sending, setSending] = useState(false);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [htmlTemplate, setHtmlTemplate] = useState('');
  const [message, setMessage] = useState('');
  const [emailList, setEmailList] = useState([]);
  const { currentUser } = useAuth();
  const [customizedHtml, setCustomizedHtml] = useState('');
  
  // Charger les emails et noms depuis Firestore
  useEffect(() => {
    if (!currentUser) {
      return <p>Vous devez être connecté pour accéder à cette page.</p>;
    }

    const fetchEmails = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'emails'));
        const emails = querySnapshot.docs.map((doc) => ({
          email: doc.id,
          prenom: doc.data().pseudo || 'Cher abonné(e)', 
        }));
        setEmailList(emails); // Stocke une liste d'objets avec email et prenom
      } catch (error) {
        console.error('Erreur lors de la récupération des emails :', error);
        setMessage('Impossible de récupérer la liste des abonnés.');
      } finally {
        setLoadingEmails(false);
      }
    };
  
    fetchEmails();
  }, [currentUser]);

  useEffect(() => {
    const updatedHtml = htmlTemplate
      .replace('{{titre}}', subject || 'Titre de la newsletter')
      .replace('{{contenu}}', content || 'Contenu de la newsletter')
      .replace('{{annee}}', new Date().getFullYear());
    setCustomizedHtml(updatedHtml);
  }, [htmlTemplate, subject, content]);  

  // Gestion de l'upload du fichier HTML
  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setHtmlTemplate(e.target.result); // Charger le contenu HTML dans l'état
    };
    reader.readAsText(file);
  };

  // Envoi de la newsletter
  const handleSendNewsletter = async (e) => {
    e.preventDefault();
  
    if (!subject.trim() || !content.trim() || !htmlTemplate) {
      setMessage('Veuillez remplir tous les champs et uploader un fichier HTML.');
      return;
    }
  
    if (emailList.length === 0) {
      setMessage('Aucun abonné disponible pour envoyer la newsletter.');
      return;
    }
  
    setSending(true);
    setMessage('');
  
    try {
      // Récupère un Firebase ID Token valide
      const firebaseToken = await currentUser.getIdToken(true);
  
      const personalizedEmails = emailList.map(({ email, prenom }) => ({
        email, // Adresse email
        prenom, // Prénom
      }));
  
      // Prépare le contenu HTML avec les variables dynamiques
      const customizedHtml = htmlTemplate 
        .replace("{{titre}}", subject)
        .replace("{{contenu}}", content)
        .replace("{{annee}}", new Date().getFullYear());
  
      // Envoie les données à la fonction backend
      const response = await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_NEWSLETTER_URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${firebaseToken}`, // Envoie directement le Firebase ID Token
          },
          body: JSON.stringify({ emailList: personalizedEmails, subject, htmlTemplate: customizedHtml }), 
        }
      );

      if (response.ok) {
        const data = await response.json();
        setMessage(`${data.message || ''}`);
      } else {
        const error = await response.json();
        setMessage(`Erreur : ${error.message || 'Erreur inconnue.'}`);
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi :', error); 
      setMessage(`Erreur : ${error.message || 'Erreur inconnue.'}`);
    } finally {
      setSending(false);
    }
  };

  if (loadingEmails) {
    return <p>Chargement des abonnés...</p>;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Gestionnaire de Newsletter</h1>

      {/* Formulaire de création de newsletter */}
      <form onSubmit={handleSendNewsletter} style={styles.form}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Sujet :</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Contenu :</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            style={styles.textarea}
            placeholder="Ajoutez du texte ou d'autres informations dynamiques..."
            required
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Fichier HTML :</label>
          <input
            type="file"
            accept=".html"
            onChange={(e) => handleFileUpload(e.target.files[0])}
            style={styles.input}
            required
          />
        </div>
        <button type="submit" style={styles.button} disabled={sending}>
          {sending ? 'Envoi en cours...' : 'Envoyer'}
        </button>
      </form>

      {/* Message de retour */}
      {message && (
        <p style={{ color: message.includes('succès') ? 'green' : 'red' }}>
          {message}
        </p>
      )}

      {/* Liste des abonnés */}
      <div style={styles.emailListContainer}>
        <h2>Abonnés ({emailList.length})</h2>
        {emailList.length > 0 ? (
          <ul style={styles.emailList}>
            {emailList.map(({ email, prenom }, index) => (
              <li key={index} style={styles.emailItem}>
                <strong>{prenom || 'Anonyme'}</strong> - {email}
              </li>
            ))}
          </ul>
        ) : (
          <p>Aucun abonné trouvé.</p>
        )}
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3>Prévisualisation :</h3>
        {customizedHtml ? (
          <div
            style={{
              border: '1px solid #ddd',
              padding: '10px',
              backgroundColor: '#fff',
              borderRadius: '5px',
            }}
            dangerouslySetInnerHTML={{ __html: customizedHtml }}
          />
        ) : (
          <p>Aucune prévisualisation disponible. Veuillez remplir les champs.</p>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#f5f5f5', // Gris clair pour éviter trop de luminosité
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    color: '#222', // Texte sombre pour un bon contraste
  },
  form: {
    marginBottom: '20px',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#333', // Couleur sombre pour une meilleure lisibilité
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '14px',
    color: '#333', // Couleur sombre
  },
  textarea: {
    width: '100%',
    height: '100px',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '14px',
    color: '#333', // Couleur sombre
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '10px',
    backgroundColor: '#007BFF', // Bleu standard
    color: '#fff', // Texte en blanc sur bouton bleu
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
  },
  emailListContainer: {
    marginTop: '20px',
  },
  emailList: {
    listStyle: 'none',
    padding: 0,
  },
  emailItem: {
    padding: '10px',
    backgroundColor: '#fff', // Blanc pour contraster avec le fond
    borderBottom: '1px solid #ddd',
    borderRadius: '5px',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#222', // Texte sombre pour un bon contraste
  },
};


export default NewsletterPage;
