export const ensembles = [
    {
      id: "Ensemble1",
      title: "Flowgraines : S'aimer soi-même & Écouter la vie en soi",
      subtitle: "Deux programmes centrés sur l'alignement à soi",
      price: 79,
      description: [
        "Ce pack inclut les deux programmes : « S'aimer soi-même » et « Écouter la vie en soi ». Un parcours complet pour une introspection profonde.",
      ],
    }
];
  
export const programmes = [
    { 
        id: "Cours1", 
        title: "Flowgraine S'aimer soi-même", 
        description: [ 
            "Plongez au cœur de vous-même à travers ce programme et découvrez comment vous aimer plus profondément. Ouvrez la porte à une vie plus authentique, en harmonie avec vos aspirations profondes. Le cours inclut une vingtaine de pages de contenu avec exercices et 15 minutes d'audio.", 
            "Un groupe de partage et des contenus supplémentaires hebdomadaires. Préparez-vous à des changements et à des retrouvailles intérieures !" 
        ], 
        path: "/formationSaimer", 
        },
        { 
        id: "Cours2", 
        title: "Flowgraine Écouter la vie en soi", 
        description: [ 
            "Écouter la vie en Soi est une redécouverte intérieure pour accueillir ce qui se joue au plus profond de nous. À travers 12 chapitres, ce cours nous invite à dépasser les résistances et auto-sabotages qui nous séparent de notre intuition.", 
            "Le cours inclut une vingtaine de pages de contenu,  13 minutes d'audio inspirant, ainsi qu'un groupe de partage et des contenus supplémentaires hebdomadaires, pour des échanges et un soutien continu." 
        ], 
        path: "/formationSecouter", 
    } 
];

export const courseToBundleMap = {
    "Cours1": "Ensemble1",
    "Cours2": "Ensemble1",
};